import React from 'react';
const Header  = () => {
    // const classes = useStyles();

    return (
       <>
<div className="br-header">
  <div className="br-header-left">
    <div className="navicon-left hidden-md-down"><a id="btnLeftMenu" href><i className="icon ion-navicon-round" /></a></div>
    <div className="navicon-left hidden-lg-up"><a id="btnLeftMenuMobile" href><i className="icon ion-navicon-round" /></a></div>
    <div className="input-group hidden-xs-down wd-170 transition">
      {/* <input id="searchbox" type="text" className="form-control" placeholder="Search" /> */}
      {/* <span className="input-group-btn">
        <button className="btn btn-secondary" type="button"><i className="fa fa-search" /></button>
      </span> */}
    </div>{/* input-group */}
  </div>{/* br-header-left */}
</div>{/* br-header */}

       </>
    )
}
export default Header;