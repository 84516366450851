import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
// import Hidden from '@material-ui/core/Hidden';
// import Navigator from './Navigator';
// import Content from './Content';
// import Header from './Header';

export const theme = createMuiTheme({
    typography: {
      h5: {
        fontWeight: 500,
        fontSize: 26,
        letterSpacing: 0.5,
      },
    },
    palette: {
      primary: {
        light: '#63ccff',
        main: '#009be5',
        dark: '#006db3',
      },
    },
    shape: {
      borderRadius: 8,
    },
    overrides: {
        MuiDrawer: {
          paper: {
            backgroundColor: '#18202c',
          },
        },
        MuiButton: {
          label: {
            textTransform: 'none',
          },
          contained: {
            boxShadow: 'none',
            '&:active': {
              boxShadow: 'none',
            },
          },
        },
        MuiTabs: {
          root: {
            marginLeft: 1,
          },
          indicator: {
            height: 3,
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
            backgroundColor: "#fff",
          },
        },
        MuiTab: {
          root: {
            textTransform: 'none',
            margin: '0 16px',
            minWidth: 0,
            padding: 0,
     
          },
        },
        MuiIconButton: {
          root: {
            padding: 1,
          },
        },
        MuiTooltip: {
          tooltip: {
            borderRadius: 4,
          },
        },
        MuiDivider: {
          root: {
            backgroundColor: '#404854',
          },
        },
        MuiListItemText: {
          primary: {
            fontWeight: 600,
          },
        },
        MuiListItemIcon: {
          root: {
            color: 'inherit',
            marginRight: 0,
            '& svg': {
              fontSize: 20,
            },
          },
        },
        MuiAvatar: {
          root: {
            width: 32,
            height: 32,
          },
        },
      },
      props: {
        MuiTab: {
          disableRipple: true,
        },
      },
      mixins: {
        toolbar: {
          minHeight: 48,
        },
      },
  });
  

  
  const drawerWidth = 256;  
  
  export const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      minHeight: '100vh',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appContent: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    mainContent: {
      flex: 1,
      padding: '48px 36px 0',
      background: '#eaeff1',
    },
  }));

// class Paperbase extends React.Component {
//   state = {
//     mobileOpen: false,
//   };

//   handleDrawerToggle = () => {
//     this.setState(state => ({ mobileOpen: !state.mobileOpen }));
//   };

//   render() {
//     const { classes } = this.props;

//     return (
//       <ThemeProvider theme={theme}>
//         <div className={classes.root}>
//           <CssBaseline />
//           <nav className={classes.drawer}>
//             <Hidden smUp implementation="js">
//               <Navigator
//                 PaperProps={{ style: { width: drawerWidth } }}
//                 variant="temporary"
//                 open={this.state.mobileOpen}
//                 onClose={this.handleDrawerToggle}
//               />
//             </Hidden>
//             <Hidden xsDown implementation="css">
//               <Navigator PaperProps={{ style: { width: drawerWidth } }} />
//             </Hidden>
//           </nav>
//           <div className={classes.appContent}>
//             <Header onDrawerToggle={this.handleDrawerToggle} />
//             <main className={classes.mainContent}>
//               <Content />
//             </main>
//           </div>
//         </div>
//       </ThemeProvider>
//     );
//   }
// }

// Paperbase.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

// export default withStyles(styles)(Paperbase);
// export theme;