import React, { useState } from 'react';
import Header from './Header';
import Navigator from './Navigator';
import AreasPage from './AreasPage';
import useEffectAsync from '../helpers/useEffectAsync';
import Modal from 'react-modal';
import { Helmet } from 'react-helmet';

const Cards = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [scooters, setScooters] = useState([]);
    const [value, setValue] = useState(null);
    const [number, setNumber] = useState(null);
    const [usedCardsCount, setusedCardsCount] = useState(0);
    const [usedCards, setusedCards] = useState(0);

    const createCards = async () => {
        const requestBody = {
            query: `
            mutation{
                createTopOffCards(number:${number}, quantity:${value})
              }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/areas");
                    // setScooters(resData.data.cards);
                    await fetchScooters();
                    // localStorage.setItem("scooters", JSON.stringify(resData.data.scootersD));
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            console.log(e);
        }
    }
    
    const fetchScooters = async () => {
        const requestBody = {
            query: `
            query{
                cards{
                  _id   
                  code
                  quantity
                  used
                  user{
                    _id
                  }
                }
              }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/areas");
                    setScooters(resData.data.cards);
                    // localStorage.setItem("scooters", JSON.stringify(resData.data.scootersD));
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            console.log(e);
        }
    }
    const fetchCount = async () => {
        const requestBody = {
            query: `
            query{
                usedCardsCount
              }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/areas");
                    setusedCardsCount(resData.data.usedCardsCount);
                    // localStorage.setItem("scooters", JSON.stringify(resData.data.scootersD));
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            console.log(e);
        }
    }
    const fetchValue = async () => {
        const requestBody = {
            query: `
            query{
                usedCardsValue
              }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/areas");
                    setusedCards(resData.data.usedCardsValue);
                    // localStorage.setItem("scooters", JSON.stringify(resData.data.scootersD));
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            console.log(e);
        }
    }
    const endNoPay = async (id) => {
        const requestBody = {
            query: `
            query{
                endRideNotPay(id: "${id}"){
                    _id
                    time
                    status 
                     user{
                       _id
                       }
                }
            }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/Rides");
                    // setScooters(resData.data.rides);

                    // localStorage.setItem("scooters", JSON.stringify(resData.data.scootersD));
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            console.log(e);
        }
    }
    const deleteD = async (id) => {
        const requestBody = {
            query: `
            mutation{
                deleteCard(id: "${id}")
            }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/Rides");
                    // setScooters(resData.data.rides);
                    await fetchScooters();
                    // localStorage.setItem("scooters", JSON.stringify(resData.data.scootersD));
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            console.log(e);
        }
    }
    // const computeTotalRevenue = () => {
    //     let TR = 0.0;
    //     let TP = 0.0;
    //     let lScooters = JSON.parse(localStorage.getItem("scooters"));
    //     lScooters.map(
    //         (scooter) => {
    //             console.log(scooter);
    //             if (scooter.rides) {
    //                 scooter.rides.map(
    //                     (ride) => {
    //                         if (ride.bill){
    //                             if(ride.bill.totalPaid)
    //                                 TR += ride.bill.totalPaid
    //                             if(ride.bill.totalUnpaid){
    //                                 TP += ride.bill.totalUnpaid
    //                             }
    //                         }
    //                     }
    //                 )
    //             }

    //         }
    //     )
    //     setTR(TR);
    // }
    useEffectAsync(
        async () => {
            await fetchScooters();
            await fetchCount();
            await fetchValue();


            // computeTotalRevenue();
            // setLoaded(true);
        }, []
    )
    return (
        <>
            <Header />
            <Navigator active={`cards`} />
            <Helmet>
                <title>Cards</title>
            </Helmet>
            <div className="br-mainpanel">
                <div className="br-pageheader pd-y-15 pd-l-20">

                </div>{/* br-pageheader */}
                <div className="pd-x-20 pd-sm-x-30 pd-t-20 pd-sm-t-30">
                    <h4 className="tx-gray-800 mg-b-5">Cards</h4>
                    <h3 className="tx-gray-800 mg-b-5"> {scooters.length} Cards ({usedCardsCount} used , LE{usedCards}) </h3>

                    {/* <p className="mg-b-0">Dashboard cards are used in an overview or summary of a project, for crm or form cms.</p> */}
                </div>

                <div>

                    <div className="d-flex align-items-center justify-content-start pd-x-20 pd-sm-x-30 pd-t-25 mg-b-20 mg-sm-b-30">
                        <button id="showSubLeft" className="btn btn-secondary mg-r-10 hidden-lg-up"><i className="fa fa-navicon" /></button>
                        {/* START: DISPLAYED FOR MOBILE ONLY */}
                        <div className="dropdown hidden-sm-up">
                            <a href="#" className="btn btn-outline-secondary" data-toggle="dropdown"><i className="icon ion-more" /></a>
                            <div className="dropdown-menu pd-10">
                                <nav className="nav nav-style-1 flex-column">
                                    <a href className="nav-link">Share</a>
                                    <a href className="nav-link">Download</a>
                                    <div className="dropdown-divider" />
                                    <a href className="nav-link">Info</a>
                                    <a href className="nav-link">Delete</a>
                                </nav>
                            </div>{/* dropdown-menu */}
                        </div>{/* dropdown */}
                        {/* END: DISPLAYED FOR MOBILE ONLY */}
                        <div className="btn-group hidden-xs-down">
                        </div>{/* btn-group */}
                        {/* <div

                                className="mg-l-auto hidden-xs-down">
                                <a href="/new-member" className="btn btn-info">New Member</a>

                            </div> */}
                    </div>{/* d-flex */}

                    <div 
                    style={{
                        width: "80%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "block"
                    }}
                    className="row no-gutters">
  <div className="col-md-6">
    <div className="form-group">
      <input className="form-control"
      
      value={number}
      onChange={(e) => {  setNumber(e.target.value) }}
      type="text" name="firstname" placeholder="Number of cards" />
    </div>
  </div>{/* col-4 */}
  <div className="col-md-6 mg-t--1 mg-md-t-0">
    <div className="form-group mg-md-l--1">
      <input className="form-control"
   
      type="text" value={value}
      onChange={(e) => {  setValue(e.target.value) }}
      placeholder="Value of each" />
      <button
      onClick={async()=>{
        createCards();
        await fetchScooters();
      }}
      className="btn btn-teal active btn-block mg-b-10"
      >
          Confirm
      </button>
    </div>
  </div>{/* col-4 */}

</div>

                    <div className="br-pagebody pd-x-20 pd-sm-x-30">
                        {/* <div className="card bd-0 shadow-base"> */}

                            {scooters.map(
                                (scooter) => {

                                    return (
                                        <div 
                                        
                                        style={{marginBottom: 20}}
                                        className="col-sm-12 col-lg-12">
                                            <div className="card shadow-base bd-0">
                                                <div className="card-header bg-transparent d-flex justify-content-between align-items-center">
                                                    <h6 className="card-title tx-uppercase tx-12 mg-b-0">Card
                                                    
                                                   
                                                    </h6>
                                                    <a onClick={()=> deleteD(scooter._id)}>
                                                          Delete
                                                    </a>
                                                    {/* <span className="tx-12 tx-uppercase">February 2017</span> */}
                                                </div>{/* card-header */}
                                                <div className="card-body">
                                                    {/* <p className="tx-sm tx-inverse tx-medium mg-b-0">Hardware Monitoring</p>
                                                        <p className="tx-12">Intel Dothraki G125H 2.5GHz</p> */}
                                                    <div className="row align-items-center">
                                                        <div className="col-3 tx-12">Cost</div>{/* col-3 */}
                                                        <div className="col-9">
                                                            <div className="progress rounded-0 mg-b-0">
                                                                <p>{scooter.quantity} L.E</p>
                                                            </div>{/* progress */}
                                                        </div>{/* col-9 */}
                                                    </div>{/* row */}
                                                    <div className="row align-items-center mg-t-5">
                                                        <div className="col-3 tx-12">Code</div>{/* col-3 */}
                                                        <div className="col-9">
                                                            <div className="progress rounded-0 mg-b-0">
                                                                <p>{scooter.code}</p>
                                                            </div>{/* progress */}
                                                        </div>{/* col-9 */}
                                                    </div>{/* row */}
                                                    <div className="row align-items-center mg-t-5">
                                                        <div className="col-3 tx-12">Used</div>{/* col-3 */}
                                                        <div className="col-9">
                                                            <div className="progress rounded-0 mg-b-0">
                                                                <p>{scooter.used?"Used":"Not used"}</p>
                                                            </div>{/* progress */}
                                                        </div>{/* col-9 */}
                                                    </div>{/* row */}
                                                </div>{/* card-body */}
                                            </div>{/* card */}
                                        </div>

                                    )
                                }
                            )}


                        {/* </div> */}
                    </div></div>

            </div>
        </>
    )
}
export default Cards;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};