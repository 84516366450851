import Header from './Header';
import Navigator from './Navigator';
import React, { useState, useEffect } from 'react';
import useEffectAsync from "../helpers/useEffectAsync";
import Moment from 'react-moment';
import * as V from 'victory';
import QRCode from 'qrcode.react';
import { VictoryStack, VictoryChart, VictoryPolarAxis, VictoryTheme, VictoryAxis, VictoryBar, VictoryArea } from 'victory';
import ReactStars from 'react-stars'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import AuthContext from '../context/auth';
import MyFancyComponent from './AreaMap1';
const User = ({ match }) => {

  // const  [address, setAddress] = useState(null);
  const [lng, setLng] = useState(null);
  const [lat, setLat] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [areaID, setAID] = useState(null);
  const [scooter, setScooter] = useState(null);
  const [name, setName] = useState(null);
  const [address, setAddress] = useState(null);
  const [totalRidesWeek, setTRW] = useState(0);

  const [data, setData] = useState([]);
  // const authD = useContext(AuthContext);

  const stopScooter = async () => {
    const requestBody = {
      query: `
        mutation{
          setScooterStatus(status: "ready", code:"${match.params.id}"){
            _id
          }
        }
        `
    }
    try {
      await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

        {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
            'Content-Type': 'application/json',
            // 'city_id': JSON.parse(localStorage.getItem("city_id"))
          }
        }
      )
        .then(res => {

          return res.json();
        }).then(async (resData) => {
          await fetchScooter();

        }).catch(err => {
          throw (err);
        })
    } catch (e) {
      // throw  new Error("t");
      console.log(e);
    }
  }
  const startScooter = async (border) => {
    const requestBody = {
      query: `
        mutation{
          setScooterStatus(status: "on", code:"${match.params.id}"  ){
            _id
          }
        }
        `
    }
    try {
      await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

        {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
            'Content-Type': 'application/json',
            // 'city_id': JSON.parse(localStorage.getItem("city_id"))
          }
        }
      )
        .then(res => {

          return res.json();
        }).then(async (resData) => {
          await fetchScooter();
        }).catch(err => {
          throw (err);
        })
    } catch (e) {
      // throw  new Error("t");
      console.log(e);
    }
  }
  // const createArea = async () => {
  //   const borders = JSON.parse(localStorage.getItem("polygon"));
  //   await borders.map(
  //     (border) => {
  //       createBorder(border);
  //     }
  //   );
  //   const requestBody = {
  //     query: `
  //       mutation{
  //         createArea(
  //           area: "${localStorage.getItem('areaid')}",
  //           name: "${name}"
  //         ){
  //           _id
  //         }
  //       }
  //       `
  //   }
  //   try {
  //     await fetch(`https://rabbit-api.app:9000/graphql/graphql`,

  //       {
  //         method: 'POST',
  //         body: JSON.stringify(requestBody),
  //         headers: {
  //           'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
  //           'Content-Type': 'application/json',
  //           // 'city_id': JSON.parse(localStorage.getItem("city_id"))
  //         }
  //       }
  //     )
  //       .then(res => {

  //         return res.json();
  //       }).then(async (resData) => {
  //         // window.location.replace("/areas");
  //       }).catch(err => {
  //         throw (err);
  //       })
  //   } catch (e) {
  //     // throw  new Error("t");
  //     console.log(e);
  //   }
  // }
  // https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/geocode/json?latlng=30.07381774797363,31.292942261658254&key=AIzaSyC6vfMlg8weB3DKX2-ZF5esMSXbCE1tHYA

  const fetchScooter = async () => {
    // createBlankArea
    const requestBody = {
      query: `
        query{
            userD(id: "${match.params.id}"){
            name
            trips{
                date
                start_time
                finish_time
                bill{
                    totalCost
                    actualCost
                }
            }

          }
        }
        `
    }
    try {
      await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

        {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
            'Content-Type': 'application/json',
            // 'city_id': JSON.parse(localStorage.getItem("city_id"))
          }
        }
      )
        .then(res => {

          return res.json();
        }).then(async (resData) => {
          setScooter(resData.data.userD);
          //   localStorage.setItem("scooter", JSON.stringify(resData.data.scooter));

          // setAID(resData.data.createBlankArea._id);
          // localStorage.setItem("areaid", resData.data.createBlankArea._id);
          // window.location.replace("/Areas");

        }).catch(err => {
          throw (err);
        })
    } catch (e) {
      // throw  new Error("t");
      console.log(e);
    }
  }
  // const handleSelect = address => {
  //     geocodeByAddress(address)
  //       .then(results => getLatLng(results[0]))
  //       .then(latLng => {

  //         console.log('Success', latLng)
  //         setLng(latLng.lng);
  //         setLat(latLng.lat);

  //       })
  //       .catch(error => console.error('Error', error));
  //   };
  useEffectAsync(
    async () => {
      // await preCreateArea();
      await fetchScooter();
      //   await createStats();
      setLoaded(true);
    }
    , []
  )
  let totalRevenue = 0;
  return (
    <>
      <Header />
      <Navigator />
      {loaded &&
        <div className="br-mainpanel">
          <div className="br-section-wrapper">
            <div className="form-layout form-layout-2">
              <div className="row no-gutters">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-control-label">Name: <span className="tx-danger">*</span></label>

                    {scooter.name}

                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-control-label">Trips: <span className="tx-danger">*</span></label>

                    {scooter.trips.length}

                  </div>
                </div>




                <div className="col-md-6">
                  <div className="form-group">
                    <b>Total rides this week: </b> {totalRidesWeek}


                    {/* <VictoryAxis
          // tickValues specifies both the number of ticks and where
          // they are placed on the axis
          tickValues={[1, 2, 3, 4]}
          tickFormat={["Quarter 1", "Quarter 2", "Quarter 3", "Quarter 4"]}
        />
        <VictoryAxis
          dependentAxis
          // tickFormat specifies how ticks should be displayed
          tickFormat={(x) => (`$${x / 1000}k`)}
        />
        <VictoryBar
          data={data}
          x="quarter"
          y="earnings"
        /> */}
                    {/* </VictoryChart> */}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    Ride History
    <table className="table mg-b-0 table-contact">
                      <thead>
                        <tr>

                          <th className="tx-10-force tx-mont tx-medium">Date</th>
                          {/* <th className="tx-10-force tx-mont tx-medium">Distance</th> */}
                          <th className="tx-10-force tx-mont tx-medium">Time</th>
                          <th className="tx-10-force tx-mont tx-medium">Bill</th>



                          <th className="wd-5p hidden-xs-down" />

                        </tr>
                      </thead>
                      <tbody>

                        {
                          scooter.trips &&
                          scooter.trips.map(
                            (ride) => {
                              const baseRidePrice = 6;
                              const pricePerMinute = 1;
                              const totalRideMunites = Math.ceil((new Date(ride.finish_time) - new Date(ride.start_time)) / 60000).toFixed(2);
                              const calculatedFees = baseRidePrice + (totalRideMunites * pricePerMinute);
                              return (
                                <tr>
                                  <td className="valign-middle">
                                    <Moment format="LLLL">{ride.date}</Moment>
                                  </td>
                                  <td className="valign-middle">
                                    {totalRideMunites}
                                  </td>
                                  <td className="valign-middle">
                                    {calculatedFees.toFixed(2)}
                                  </td>
                                  <span style={{ color: "#FFF" }}>
                                    {ride.bill &&
                                      <>
                                        {totalRevenue += calculatedFees} </>
                                    }
                                  </span>
                                </tr>
                              )
                            }
                          )
                        }
                        <td></td>
                        <td></td>
                        <td>
                          {totalRevenue.toFixed(2)} L.E
                  </td>

                      </tbody>
                    </table>
                  </div>
                </div>
                {/* <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-control-label">Service area:
         <span className="tx-danger">*</span></label>
                    <b>{scooter.area.name}</b>

                    <MyFancyComponent
                      coords={scooter.area.borders}
                    />

                  </div>
                </div> */}

              </div>{/* row */}
              <div className="form-layout-footer bd pd-20 bd-t-0">
                {/* <button
                  // onClick={createArea}
                  className="btn btn-info">Create</button> */}
                {/* <button className="btn btn-secondary">Cancel</button> */}
              </div>{/* form-group */}
            </div>{/* form-layout */}
          </div>
        </div>
      }
    </>
  )
}
export default User;