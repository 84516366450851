import React, {useState, useEffect} from 'react';
import useEffectAsync from './helpers/useEffectAsync';
// import {useStyles} from './Theme/theme';  
import MainPage from './Views/MainPage';
import TFA from './Views/TFA';
import Areas from './Views/Areas';
import NewArea from './Views/NewArea';
import NewMember from './Views/NewMember';

import NewScooter from './Views/NewScooter';
import NewPromocode from './Views/NewPromocode';
import Users from './Views/Users';
import Scooters from './Views/Scooters';
import Subscriptions from './Views/Subscriptions';


import Scooter from './Views/Scooter';
import User from './Views/User';

import Rides from './Views/Rides';

import Area from './Views/Area';

import Promocode from './Views/Promocode';
import OperationsTeam from './Views/OperationsTeam';


import PromoCodes from './Views/PromoCodes';
import Cards from './Views/Cards';

import {BrowserRouter as Router, Link, Route} from 'react-router-dom';
import { PropsRoute, PublicRoute, PrivateRoute } from 'react-router-with-props'
import AuthContext from './context/auth';

import './App.css';

function App() {
  const [loginState, updateToken] = useState({
    token: null,
    userId: null,
    tokenExpiration: null
  });
  const loginFunction = async (token, userId, tokenExpiration) => {
    try{
      const t = {token: token, userId: userId, tokenExpiration:  tokenExpiration};
      await updateToken(t);
      console.log(t);
      localStorage.setItem('login', JSON.stringify(t));
      console.log(JSON.parse(localStorage.getItem('login')))
    }catch(error){
  
    }
  }
  // useEffectAsync( async() => {
  //   const localLogin = JSON.parse(await localStorage.getItem("login"));
  //   if(localLogin){
  //     updateToken(localLogin);
  //   }

  // },[]);
  const logoutFunction = () => {
    localStorage.removeItem("login");
  }
  return (
    <Router>
      <AuthContext.Provider value={{token: loginState.token, userId: loginState.userId, login: loginFunction, logout: logoutFunction}}>
    <Route exact path="/" component={MainPage} />
    <Route exact path="/TFA" component={TFA} />
    <Route exact path="/Areas" component={Areas} />
    <Route exact path="/Scooters" component={Scooters} />

    <Route exact path="/Users" component={Users} />

    <Route exact path="/new-area" component={NewArea} />
    <Route exact path="/new-scooter" component={NewScooter} />
    <Route exact path="/new-promocode" component={NewPromocode} />

    <Route exact path="/scooter/:id" component={Scooter} />
    <Route exact path="/user/:id" component={User} />

    <Route exact path="/promocode/:id" component={Promocode} />
    <Route exact path="/area/:id" component={Area} />


    <Route exact path="/Promocodes" component={PromoCodes} />
    <Route exact path="/Operations" component={OperationsTeam} />
    <Route exact path="/new-member" component={NewMember} />
    <Route exact path="/Subscriptions" component={Subscriptions} />
    <Route exact path="/Rides" component={Rides} />
    <Route exact path="/Cards" component={Cards} />





       {/* <PropsRoute exact path="/" component={MainPage} loginState={loginState} />
   
   <PropsRoute path="/TFA" component={TFA} loginState={loginState} />
         */}
      </AuthContext.Provider>
    </Router>
  );
}

export default App;
