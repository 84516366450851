import Header from './Header';
import Navigator from './Navigator';
import React, { useState, useEffect } from 'react';
import useEffectAsync from "../helpers/useEffectAsync";
import Moment from 'react-moment';
import * as V from 'victory';
import QRCode from 'qrcode.react';
import { VictoryStack, VictoryChart, VictoryPolarAxis, VictoryTheme, VictoryAxis, VictoryBar, VictoryArea } from 'victory';
import ReactStars from 'react-stars'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import AuthContext from '../context/auth';
import MyFancyComponent from './AreaMap1';
import Geocode from "react-geocode";

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyC6vfMlg8weB3DKX2-ZF5esMSXbCE1tHYA");
// set response language. Defaults to english.
Geocode.setLanguage("ar");
Geocode.setRegion("eg");
Geocode.enableDebug();

// Get address from latitude & longitude.
const Scooter = ({ match }) => {

  // const  [address, setAddress] = useState(null);
  const [lng, setLng] = useState(null);
  const [lat, setLat] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [areaID, setAID] = useState(null);
  const [scooter, setScooter] = useState(null);
  const [name, setName] = useState(null);
  const [address, setAddress] = useState(null);
  const [areas, setAreas] = useState([]);

  const [totalRidesWeek, setTRW] = useState(0);

  const [data, setData] = useState([]);
  // const authD = useContext(AuthContext);
  const fetchAreas = async (border) => {
    const requestBody = {
      query: `
      query{
        areas{
            _id
            name
        }
      }
      `
    }
    try {
      await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

        {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
            'Content-Type': 'application/json',
            // 'city_id': JSON.parse(localStorage.getItem("city_id"))
          }
        }
      )
        .then(res => {

          return res.json();
        }).then(async (resData) => {
          setAreas(resData.data.areas);
        }).catch(err => {
          throw (err);
        })
    } catch (e) {
      // throw  new Error("t");
      console.log(e);
    }
  }
  const updateArea = async (border) => {
    const requestBody = {
      query: `
      mutation{
        updateArea(id: "${scooter._id}", area:"${areaID}")
      }
      `
    }
    try {
      await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

        {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
            'Content-Type': 'application/json',
            // 'city_id': JSON.parse(localStorage.getItem("city_id"))
          }
        }
      )
        .then(res => {

          return res.json();
        }).then(async (resData) => {
          setAreas(resData.data.areas);
        }).catch(err => {
          throw (err);
        })
    } catch (e) {
      // throw  new Error("t");
      console.log(e);
    }
  }

  const createStats = async () => {
    let days = [];
    const today = new Date();
    for (let i = 6; i > 0; i--) {
      let day = new Date();
      day.setDate(day.getDate() - i);
      days.push(day.toISOString());
      // let day =  today.getDate() - )
    }
    days.push(today.toISOString());
    const scooter = JSON.parse(await localStorage.getItem("scooter"));
    let rides = []
    scooter.rides &&
      scooter.rides.map(
        (ride) => {
          let rideObject = {};
          rideObject = ride;
          rideObject.date = new Date(ride.date).getDate() + "-" + new Date(ride.date).getMonth() + "-" + new Date(ride.date).getYear();
          rides.push(rideObject);
          // console.log(new Date(ride.date).getDate(),new Date(ride.date).getMonth());
        }
      )
    let totals = []
    var dayNames = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
    let totalCount = 0;
    days.map(
      (day) => {
        let dayObject = {};
        let count = 0;
        let dayK = new Date(day).getDate() + "-" + new Date(day).getMonth() + "-" + new Date(day).getYear()
        dayObject.x = dayNames[new Date(day).getDay()];
        rides.map(
          (ride) => {
            if (ride.date === dayK) {
              count += 1;
              totalCount += 1;
            }
          }
        )
        dayObject.y = count;
        totals.push(dayObject);
      }
    )
    setData(totals);
    setTRW(totalCount);
    console.log(totals);
  }
  const stopScooter = async () => {
    const requestBody = {
      query: `
        mutation{
          setScooterStatus(status: "ready", code:"${match.params.id}"){
            _id
          }
        }
        `
    }
    try {
      await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

        {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
            'Content-Type': 'application/json',
            // 'city_id': JSON.parse(localStorage.getItem("city_id"))
          }
        }
      )
        .then(res => {

          return res.json();
        }).then(async (resData) => {
          await fetchScooter();

        }).catch(err => {
          throw (err);
        })
    } catch (e) {
      // throw  new Error("t");
      console.log(e);
    }
  }
  const startScooter = async (border) => {
    const requestBody = {
      query: `
        mutation{
          setScooterStatus(status: "on", code:"${match.params.id}"  ){
            _id
          }
        }
        `
    }
    try {
      await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

        {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
            'Content-Type': 'application/json',
            // 'city_id': JSON.parse(localStorage.getItem("city_id"))
          }
        }
      )
        .then(res => {

          return res.json();
        }).then(async (resData) => {
          await fetchScooter();
        }).catch(err => {
          throw (err);
        })
    } catch (e) {
      // throw  new Error("t");
      console.log(e);
    }
  }
  // const createArea = async () => {
  //   const borders = JSON.parse(localStorage.getItem("polygon"));
  //   await borders.map(
  //     (border) => {
  //       createBorder(border);
  //     }
  //   );
  //   const requestBody = {
  //     query: `
  //       mutation{
  //         createArea(
  //           area: "${localStorage.getItem('areaid')}",
  //           name: "${name}"
  //         ){
  //           _id
  //         }
  //       }
  //       `
  //   }
  //   try {
  //     await fetch(`https://rabbit-api.app:9000/graphql/graphql`,

  //       {
  //         method: 'POST',
  //         body: JSON.stringify(requestBody),
  //         headers: {
  //           'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
  //           'Content-Type': 'application/json',
  //           // 'city_id': JSON.parse(localStorage.getItem("city_id"))
  //         }
  //       }
  //     )
  //       .then(res => {

  //         return res.json();
  //       }).then(async (resData) => {
  //         // window.location.replace("/areas");
  //       }).catch(err => {
  //         throw (err);
  //       })
  //   } catch (e) {
  //     // throw  new Error("t");
  //     console.log(e);
  //   }
  // }
  // https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/geocode/json?latlng=30.07381774797363,31.292942261658254&key=AIzaSyC6vfMlg8weB3DKX2-ZF5esMSXbCE1tHYA

  const fetchScooter = async () => {
    // createBlankArea
    const requestBody = {
      query: `
        query{
            scooter(code: "${match.params.id}"){
                _id
                  code
                  long
                  lat
                  area{
                      name
                      borders{
                          lng
                          lat
                      }
                  }
                  lastModification
                  modifiers
                  status
                  rating
                  phoneBattery
                  battery
                  iotStatus
                  rides{
                    date
                    time
                    user{
                      _id
                      name
                    }
                    bill{
                      totalCost
                      totalPaid
                    }

                  }
                  ratings{
                    _id
                  }

          }
        }
        `
    }
    try {
      await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

        {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
            'Content-Type': 'application/json',
            // 'city_id': JSON.parse(localStorage.getItem("city_id"))
          }
        }
      )
        .then(res => {

          return res.json();
        }).then(async (resData) => {
          const { scooter } = resData.data;
          setScooter(scooter);
          localStorage.setItem("scooter", JSON.stringify(resData.data.scooter));
          const addressResponse = await Geocode.fromLatLng(scooter.lat, scooter.long);
          setAddress(addressResponse.results[0].formatted_address);

        }).catch(err => {
          throw (err);
        })
    } catch (e) {
      // throw  new Error("t");
      console.log(e);
    }
  }
  // const handleSelect = address => {
  //     geocodeByAddress(address)
  //       .then(results => getLatLng(results[0]))
  //       .then(latLng => {

  //         console.log('Success', latLng)
  //         setLng(latLng.lng);
  //         setLat(latLng.lat);

  //       })
  //       .catch(error => console.error('Error', error));
  //   };
  useEffectAsync(
    async () => {
      // await preCreateArea();
      await fetchScooter();
      await fetchAreas();

      await createStats();
      setLoaded(true);
    }
    , []
  )
  let totalRevenue = 0;
  return (
    <>
      <Header />
      <Navigator />
      {loaded &&
        <div className="br-mainpanel">
          <div className="br-section-wrapper">
            <div className="form-layout form-layout-2">
              <div className="row no-gutters">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-control-label">Scooter Name: <span className="tx-danger">*</span></label>

                    {scooter.code}

                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-control-label">Status: <span className="tx-danger">*</span></label>

                    {scooter.status}

                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-control-label">Last updated: <span className="tx-danger">*</span></label>

                    <Moment fromNow>{scooter.lastModification}</Moment>

                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-control-label">IoT Status: <span className="tx-danger">*</span></label>

                    {scooter.iotStatus}

                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-control-label">Current status: <span className="tx-danger">{scooter.status}</span></label>

                    {(scooter.status === "off" || scooter.status === "ready") ?
                      <button
                        onClick={startScooter}
                        className="btn btn-success active btn-block mg-b-10">Start Scooter</button>
                      :
                      <button
                        onClick={stopScooter}
                        className="btn btn-danger active btn-block mg-b-10">Stop Scooter</button>

                    }
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-control-label">Current location: <span className="tx-danger">*</span></label>

                    {address}

                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-control-label">LAT/LNG: <span className="tx-danger">*</span></label>
                    {scooter.lat}/{scooter.long}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-control-label">Rating<span className="tx-danger">*</span></label>

                    <ReactStars
                      count={5}
                      value={scooter.rating}
                      size={24}
                      color2={'#ffd700'}
                      edit={false}
                    />
                    Total ratings: {scooter.ratings && scooter.ratings.length}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-control-label">Phone Battery/Scooter Distance<span className="tx-danger">*</span></label>

                    {scooter.phoneBattery}%/{((scooter.battery/0.2)+12).toFixed(2)}%
                  </div>
                </div>
                <div className="col-md-12">
                  <QRCode size={256} value={scooter.code} />

                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <b>Total rides this week: </b> {totalRidesWeek}
                    <VictoryChart
                      // domainPadding will add space to each side of VictoryBar to
                      // prevent it from overlapping the axis
                      theme={VictoryTheme.material}
                      domainPadding={20}
                    >
                      <VictoryArea
                        theme={VictoryTheme.material}
                        data={data} />
                      <VictoryAxis />
                    </VictoryChart>

                    {/* <VictoryAxis
          // tickValues specifies both the number of ticks and where
          // they are placed on the axis
          tickValues={[1, 2, 3, 4]}
          tickFormat={["Quarter 1", "Quarter 2", "Quarter 3", "Quarter 4"]}
        />
        <VictoryAxis
          dependentAxis
          // tickFormat specifies how ticks should be displayed
          tickFormat={(x) => (`$${x / 1000}k`)}
        />
        <VictoryBar
          data={data}
          x="quarter"
          y="earnings"
        /> */}
                    {/* </VictoryChart> */}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    Ride History
    <table className="table mg-b-0 table-contact">
                      <thead>
                        <tr>

                          <th className="tx-10-force tx-mont tx-medium">Time</th>
                          <th className="tx-10-force tx-mont tx-medium">User</th>
                          {/* <th className="tx-10-force tx-mont tx-medium">Distance</th> */}
                          <th className="tx-10-force tx-mont tx-medium">Time</th>
                          <th className="tx-10-force tx-mont tx-medium">Bill</th>



                          <th className="wd-5p hidden-xs-down" />

                        </tr>
                      </thead>
                      <tbody>

                        {
                          scooter.rides &&
                          scooter.rides.map(
                            (ride) => {
                              return (
                                <tr>
                                  <td className="valign-middle">
                                    <Moment fromNow>{ride.date}</Moment>
                                  </td>
                                  <td className="valign-middle">
                                    {ride.user && ride.user.name}
                                  </td>
                                  <td className="valign-middle">
                                    {ride.time}
                                  </td>
                                  <td className="valign-middle">
                                    {ride.bill && ride.bill.totalCost} L.E
                        </td>
                                  <span style={{ color: "#FFF" }}>
                                    {ride.bill &&
                                      <>
                                        {(totalRevenue += ride.bill.totalCost).toFixed(2)} </>
                                    }
                                  </span>
                                </tr>
                              )
                            }
                          )
                        }
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          {totalRevenue.toFixed(2)} L.E
                  </td>

                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-control-label">Service area:
         <span className="tx-danger">*</span></label>
                    <b>{scooter.area.name}</b>

                    <MyFancyComponent
                      coords={scooter.area.borders}
                    />

                  </div>
                </div>

              </div>{/* row */}
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-control-label">Area: <span className="tx-danger">*</span></label>

                  <select
                    onChange={
                      (e) => setAID(e.target.value)
                    }
                  >
                    <option></option>
                    {areas.map(
                      (area) => {
                        return <option value={area._id}>{area.name}</option>
                      }
                    )}
                  </select>
                </div>
              </div>
              <div className="form-layout-footer bd pd-20 bd-t-0">
                <button
                  onClick={updateArea}
                  className="btn btn-info">Create</button>
                {/* <button className="btn btn-secondary">Cancel</button> */}
              </div>{/* form-group */}
            </div>{/* form-layout */}
          </div>
        </div>
      }
    </>
  )
}
export default Scooter;