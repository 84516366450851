import React, { useState } from 'react';
import Moment from 'react-moment';

const PCodeComponent = ({promocode, deletePromocode}) => {
    const [visible, setVisible] = useState(true);
    return (
        <>
        {visible&&
        <tr>
            <td className="valign-middle">
                <label className="ckbox mg-b-0">
                    <input type="checkbox" /><span />
                </label>
            </td>

            <td>
                <div className="d-flex align-items-center">
                    {/* <img src="http://via.placeholder.com/280x280" className="wd-40 rounded-circle" alt /> */}
                    <div className="mg-l-15">
                        <div className="tx-inverse">
                            {promocode.code}
                        </div>
                        {/* <span className="tx-12">Cairo</span>
                    <span id="sparkline1">1,4,4,7,5,9,4,7,5,9,1</span> */}
                    </div>
                </div>
            </td>

            <td className="valign-middle hidden-xs-down">
                {promocode.type}
            </td>
            <td className="valign-middle hidden-xs-down">
                {promocode.quantity}
            </td>
            <td className="valign-middle hidden-xs-down">
                {promocode.users && promocode.users.length}
            </td>
            <td className="valign-middle hidden-xs-down">
                {promocode.rides && promocode.rides.length}

            </td>
            <td className="valign-middle hidden-xs-down">
                <Moment fromNow>{promocode.expires}</Moment>
            </td>
            <td className="dropdown hidden-xs-down">
                <a href="#" data-toggle="dropdown" className="btn pd-y-3 tx-gray-500 hover-info"><i className="icon ion-more" /></a>
                <div className="dropdown-menu dropdown-menu-right pd-10">
                    <nav className="nav nav-style-1 flex-column">
                        {/* <a href className="nav-link">Info</a> */}
                        <a href={`/promocode/${promocode.code}`} className="nav-link">Info</a>
                        <a
                            onClick={() =>{ 
                                deletePromocode(promocode._id);
                                setVisible(false);
                            } }
                            href className="nav-link">Delete</a>
                    </nav>
                </div>{/* dropdown-menu */}
            </td>
        </tr>
        }
        </>
    )
}
export default PCodeComponent;      