import React, { useState } from 'react';
import Header from './Header';
import Main from './Main';
import Navigator from './/Navigator';
import LoginPage from './LoginPage';
import Users from './Users';

import AuthContext from '../context/auth';

import useEffectAsync from '../helpers/useEffectAsync';

const MainPage = () => {
    const [loginState, setLoginState] = useState({});
    const [loaded, setLoaded] = useState(false);

    useEffectAsync(async () => {
        const localLogin = JSON.parse(localStorage.getItem("login"));
        if (localLogin) {
            setLoginState(localLogin);
        }
        setLoaded(true);
    });
    return (

        <>
            {/* {loaded?
            <>
                {loginState?
        <> */}
            {loginState.token ?
                <>
                    <Navigator active="mainpage" />
                    <Header />
                    <Main />
                </>
                :
                <LoginPage />
            }
        </>

    );
}
export default MainPage;