import React, { useState } from 'react';
import Header from './Header';
import Navigator from './Navigator';
import AreasPage from './AreasPage';
import Modal from 'react-modal';

const Areas = () => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <Header />
            <Navigator active={`areas`} />
            <AreasPage />
        </>
    )
}
export default Areas;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};