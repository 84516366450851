import React, {useState} from "react"
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polygon, InfoWindow } from "react-google-maps"
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
// import PMarker from './PMarker';
const google = window.google; 

const PMarker = ({scooter}) => {
    const [open, setOpen] = useState(false);
    return (
        <Marker
        position={
          {
            lat: scooter.lat,
            lng: scooter.long,
          }
        }
        icon={{
            url: 'https://cdn1.iconfinder.com/data/icons/transportation-symbol/91/transportation_a-25-512.png',
            scaledSize: new google.maps.Size(32, 32)
            // size:  new window.google.maps.Size(32, 32)
        }}
        onClick={()=> setOpen(true  )}
        >
        {open &&
        	<InfoWindow
						onCloseClick={()=> setOpen(false)}
						>
					<>
                    <h4>
                        {scooter.code}
                        </h4>
                        Status: {scooter.status} <br />
                        Battery: {scooter.battery} <br />
                        <button>
                            Guide
                        </button>
                        <button onClick={
                            () => window.location.replace(`/scooter/${scooter.code}`)
                        }>
                            Respond
                        </button>
                        </>
                      
				</InfoWindow>
        }
        </Marker>
        
    )
} 
export default PMarker;