import Header from './Header';
import Navigator from './Navigator';
import React, { useState, useEffect } from 'react';
import useEffectAsync from "../helpers/useEffectAsync";
import Moment from 'react-moment';
import * as V from 'victory';
import QRCode from 'qrcode.react';
import { VictoryStack, VictoryChart, VictoryPolarAxis, VictoryTheme, VictoryAxis, VictoryBar, VictoryArea } from 'victory';
import ReactStars from 'react-stars'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import AuthContext from '../context/auth';
import MyFancyComponent from './AreaMap1';
const Promocode = ({ match }) => {

  // const  [address, setAddress] = useState(null);
  const [lng, setLng] = useState(null);
  const [lat, setLat] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [areaID, setAID] = useState(null);
  const [scooter, setScooter] = useState(null);
  const [name, setName] = useState(null);
  const [address, setAddress] = useState(null);
  const [totalRidesWeek, setTRW] = useState(0);

  const [data, setData] = useState([]);
  // const authD = useContext(AuthContext);
  const createStats = async () => {
    let days = [];
    const today = new Date();
    for (let i = 6; i > 0; i--) {
      let day = new Date();
      day.setDate(day.getDate() - i);
      days.push(day.toISOString());
      // let day =  today.getDate() - )
    }
    days.push(today.toISOString());
    const scooter = JSON.parse(await localStorage.getItem("promocode"));
    let trips = []
    scooter.trips&&
    scooter.trips.map(
      (ride) => {
        let rideObject = {};
        rideObject = ride;
        rideObject.date = new Date(ride.date).getDate() + "-" + new Date(ride.date).getMonth() + "-" + new Date(ride.date).getYear();
        trips.push(rideObject);
        // console.log(new Date(ride.date).getDate(),new Date(ride.date).getMonth());
      }
    )
    let totals = []
    var dayNames = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
    let totalCount = 0;
    days.map(
      (day) => {
        let dayObject = {};
        let count = 0;
        let dayK = new Date(day).getDate() + "-" + new Date(day).getMonth() + "-" + new Date(day).getYear()
        dayObject.x = dayNames[new Date(day).getDay()];
        trips.map(
          (ride) => {
            if (ride.date === dayK) {
              count += 1;
              totalCount += 1;
            }
          }
        )
        dayObject.y = count;
        totals.push(dayObject);
      }
    )
    setData(totals);
    setTRW(totalCount);
    console.log(totals);
  }
  const createBorder = async (border) => {
    const requestBody = {
      query: `
        mutation{
          createBorder(
            area: "${localStorage.getItem('areaid')}",
            lng: ${border.long},
            lat: ${border.lat}
          ){
            _id
          }
        }
        `
    }
    try {
      await fetch(`http://localhost:9000/graphql`,

        {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
            'Content-Type': 'application/json',
            // 'city_id': JSON.parse(localStorage.getItem("city_id"))
          }
        }
      )
        .then(res => {

          return res.json();
        }).then(async (resData) => {

        }).catch(err => {
          throw (err);
        })
    } catch (e) {
      // throw  new Error("t");
      console.log(e);
    }
  }
  const createArea = async () => {
    const borders = JSON.parse(localStorage.getItem("polygon"));
    await borders.map(
      (border) => {
        createBorder(border);
      }
    );
    const requestBody = {
      query: `
        mutation{
          createArea(
            area: "${localStorage.getItem('areaid')}",
            name: "${name}"
          ){
            _id
          }
        }
        `
    }
    try {
      await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

        {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
            'Content-Type': 'application/json',
            // 'city_id': JSON.parse(localStorage.getItem("city_id"))
          }
        }
      )
        .then(res => {

          return res.json();
        }).then(async (resData) => {
          // window.location.replace("/areas");
        }).catch(err => {
          throw (err);
        })
    } catch (e) {
      // throw  new Error("t");
      console.log(e);
    }
  }
  // https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/geocode/json?latlng=30.07381774797363,31.292942261658254&key=AIzaSyC6vfMlg8weB3DKX2-ZF5esMSXbCE1tHYA

  const fetchScooter = async () => {
    // createBlankArea
    const requestBody = {
      query: `
        query{
            promocode(code: "${match.params.id}"){
                code
                type
                expires
                quantity
                trips{
                  date
                  time
                  user{
                    _id
                    name
                  }
                  bill{
                    totalCost
                    totalPaid
                  }

                }
          

          }
        }
        `
    }
    try {
      await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

        {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
            'Content-Type': 'application/json',
            // 'city_id': JSON.parse(localStorage.getItem("city_id"))
          }
        }
      )
        .then(res => {

          return res.json();
        }).then(async (resData) => {
          setScooter(resData.data.promocode);
          localStorage.setItem("promocode", JSON.stringify(resData.data.promocode));
          await fetch(`https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/geocode/json?latlng=${resData.data.scooter.lat},${resData.data.scooter.long}&key=AIzaSyC6vfMlg8weB3DKX2-ZF5esMSXbCE1tHYA`,
            {
              method: 'GET',
              headers: {
                // 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                'Content-Type': 'application/json',
                // 'city_id': JSON.parse(localStorage.getItem("city_id"))
              }
            }).then(res => {
              return res.json();
            }).then(async (resData) => {
              setAddress(resData.results[0].formatted_address);
            })
          // setAID(resData.data.createBlankArea._id);
          // localStorage.setItem("areaid", resData.data.createBlankArea._id);
          // window.location.replace("/Areas");

        }).catch(err => {
          throw (err);
        })
    } catch (e) {
      // throw  new Error("t");
      console.log(e);
    }
  }
  // const handleSelect = address => {
  //     geocodeByAddress(address)
  //       .then(results => getLatLng(results[0]))
  //       .then(latLng => {

  //         console.log('Success', latLng)
  //         setLng(latLng.lng);
  //         setLat(latLng.lat);

  //       })
  //       .catch(error => console.error('Error', error));
  //   };
  useEffectAsync(
    async () => {
      // await preCreateArea();
      await fetchScooter();
      await createStats();
      setLoaded(true);
    }
    , []
  )
  let totalRevenue =0;
  return (
    <>
      <Header />
      <Navigator />
      {loaded &&
        <div className="br-mainpanel">
          <div className="br-section-wrapper">
            <div className="form-layout form-layout-2">
              <div className="row no-gutters">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-control-label">Code: <span className="tx-danger">*</span></label>

                    {scooter.code}

                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-control-label">Type: <span className="tx-danger">*</span></label>

                    {scooter.type}

                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-control-label">Quantity: <span className="tx-danger">*</span></label>

                   {scooter.quantity}

                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-control-label">Expires <span className="tx-danger">*</span></label>

                    <Moment fromNow>{scooter.expires}</Moment>

                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <b>Total rides this week: </b> {totalRidesWeek}
                    <VictoryChart
                      // domainPadding will add space to each side of VictoryBar to
                      // prevent it from overlapping the axis
                      theme={VictoryTheme.material}
                      domainPadding={20}
                    >
                      <VictoryArea
                        theme={VictoryTheme.material}
                        data={data} />
                      <VictoryAxis />
                    </VictoryChart>

                    {/* <VictoryAxis
          // tickValues specifies both the number of ticks and where
          // they are placed on the axis
          tickValues={[1, 2, 3, 4]}
          tickFormat={["Quarter 1", "Quarter 2", "Quarter 3", "Quarter 4"]}
        />
        <VictoryAxis
          dependentAxis
          // tickFormat specifies how ticks should be displayed
          tickFormat={(x) => (`$${x / 1000}k`)}
        />
        <VictoryBar
          data={data}
          x="quarter"
          y="earnings"
        /> */}
                    {/* </VictoryChart> */}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    Ride History
    <table className="table mg-b-0 table-contact">
                      <thead>
                        <tr>
                       
                          <th className="tx-10-force tx-mont tx-medium">Time</th>
                          <th className="tx-10-force tx-mont tx-medium">User</th>
                          {/* <th className="tx-10-force tx-mont tx-medium">Distance</th> */}
                          <th className="tx-10-force tx-mont tx-medium">Time</th>
                          <th className="tx-10-force tx-mont tx-medium">Bill</th>



                          <th className="wd-5p hidden-xs-down" />

                        </tr>
                      </thead>
                      <tbody>
                      
                  {
                   scooter.trips&& 
                    scooter.trips.map(
                      (ride) => {
                        return(
                        <tr>
                        <td className="valign-middle">
                            <Moment fromNow>{ride.date}</Moment>
                        </td>
                        <td className="valign-middle">
                            {ride.user.name}
                        </td>
                        <td className="valign-middle">
                           {ride.time} 
                        </td>
                        <td className="valign-middle">
                            {ride.bill&& ride.bill.totalCost} L.E
                        </td>
                       <span style={{color:"#FFF"}}>
                       {ride.bill&&
                       <>
                       {totalRevenue+=ride.bill.totalCost} </>
                       }
                       </span>
                        </tr>
                        )
                      }
                    )
                  }
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    {totalRevenue} L.E
                  </td>

                      </tbody>
                    </table>
                  </div>
                </div>
  

              </div>{/* row */}
    
            </div>{/* form-layout */}
          </div>
        </div>
      }
    </>
  )
}
export default Promocode;