import React, { useState } from 'react';
import Header from './Header';
import Navigator from './Navigator';
import ScootersPage from './ScootersPage';
import Modal from 'react-modal';

const Scooters = () => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <Header />
            <Navigator active={`scooters`} />
            <ScootersPage />
            
        </>
    )
}
export default Scooters;