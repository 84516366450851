import React, { useState, useContext } from 'react';
import AuthContext from '../context/auth';

const LoginPage = () => {
  const [username, setUser] = useState(null);
  const [password, setPassword] = useState(null);
  const context = useContext(AuthContext);

  const login = async () => {
    const requestBody = {
      query: `
            query{
              finalLogin(Login: "${username}", password: "${password}"){
                userId
                token
                tokenExpiration
              }
            }
            `
    }
    try {
      await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

        {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            // 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
            'Content-Type': 'application/json',
            // 'city_id': JSON.parse(localStorage.getItem("city_id"))
          }
        }
      )
        .then(res => {

          return res.json();
        }).then(async (resData) => {

            console.log(resData.data.finalLogin); 
            localStorage.setItem("login", JSON.stringify(resData.data.finalLogin));
            window.location.replace('/');
            context.login(resData.data.finalLogin.token, resData.data.finalLogin.userId, resData.data.finalLogin.tokenExpiration);
            // localStorage.setItem("loginName", username);
            // window.location.replace("/TFA");
          
        }).catch(err => {
          throw (err);
        })
    } catch (e) {
      // throw  new Error("t");
      console.log(e);
    }
  }
  return (
    <>
      <div className="d-flex align-items-center justify-content-center bg-br-primary ht-100v">
        <div className="login-wrapper wd-300 wd-xs-350 pd-25 pd-xs-40 bg-white rounded shadow-base">
          <div className="signin-logo tx-center tx-28 tx-bold tx-inverse"><span className="tx-normal">[</span> Rabbit <span className="tx-normal">]</span></div>
          <div className="form-group">
            <input type="text"
              value={username}
              onChange={(e) => { setUser(e.target.value) }}
              className="form-control" placeholder="Enter your username" />
          </div>{/* form-group */}
          <div className="form-group">
            <input
              value={password}
              onChange={(e) => { setPassword(e.target.value) }}
              type="password" className="form-control" placeholder="Enter your password" />

          </div>{/* form-group */}
          <button
            onClick={login}
            type="submit" className="btn btn-info btn-block">Sign In</button>
        </div>{/* login-wrapper */}
      </div>{/* d-flex */}

    </>
  )
}
export default LoginPage;