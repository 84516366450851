import React, {useState} from 'react';
import ReactStars from 'react-stars'
// import AreasPage from './AreasPage';
import Modal from 'react-modal';
import QRCode from 'qrcode.react'   ;
const ScooterComponent = ({revenue, scooter, deleteScooter}) => {
    const [visible, setVisible] = useState(true);
    return (
        <>
            {visible &&
                <tr>
                    <td className="valign-middle">
                        <label className="ckbox mg-b-0">
                            <input type="checkbox" /><span />
                        </label>
                    </td>

                    <td>
                        <div className="d-flex align-items-center">
                            {/* <img src="http://via.placeholder.com/280x280" className="wd-40 rounded-circle" alt /> */}
                            <div className="mg-l-15">
                                <div className="tx-inverse">
                                    {scooter.code}
                                </div>
                                {/* <span className="tx-12">Cairo</span>
                    <span id="sparkline1">1,4,4,7,5,9,4,7,5,9,1</span> */}
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="d-flex align-items-center">
                            {/* <img src="http://via.placeholder.com/280x280" className="wd-40 rounded-circle" alt /> */}
                            <div className="mg-l-15">
                                <div className="tx-inverse">
                                    {scooter.battery}
                                </div>
                                {/* <span className="tx-12">Cairo</span>
                    <span id="sparkline1">1,4,4,7,5,9,4,7,5,9,1</span> */}
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="d-flex align-items-center">
                            {/* <img src="http://via.placeholder.com/280x280" className="wd-40 rounded-circle" alt /> */}
                            <div className="mg-l-15">
                                <div className="tx-inverse">
                                    {scooter.phoneBattery}
                                </div>
                                {/* <span className="tx-12">Cairo</span>
                    <span id="sparkline1">1,4,4,7,5,9,4,7,5,9,1</span> */}
                            </div>
                        </div>
                    </td>
                    <td>

                        <QRCode size={64} value={scooter.code} />

                    </td>
                    <td className="valign-middle hidden-xs-down">
                        {scooter.area.name}
                    </td>
                    <td className="valign-middle hidden-xs-down">
                        {scooter.rides.length}
                    </td>
                    <td className="valign-middle hidden-xs-down">
                        <ReactStars
                            count={5}
                            value={scooter.rating ? scooter.rating : 0}
                            size={24}
                            color2={'#ffd700'}
                            edit={false}
                        />
                    </td>
                    <td className="valign-middle hidden-xs-down">
                        {revenue.toFixed(2)}
                    </td>
                    <td className="dropdown hidden-xs-down">
                        <a href="#" data-toggle="dropdown" className="btn pd-y-3 tx-gray-500 hover-info"><i className="icon ion-more" /></a>
                        <div className="dropdown-menu dropdown-menu-right pd-10">
                            <nav className="nav nav-style-1 flex-column">
                                {/* <a href className="nav-link">Info</a> */}
                                <a href={`/scooter/${scooter.code}`} className="nav-link">Info</a>
                                <a
                                style={{
                                    cursor: "pointer"
                                }}
                                    onClick={() => {
                                        deleteScooter(scooter.code);
                                        setVisible(false);
                                    }}
                                         className="nav-link">Delete</a>
                            </nav>
                        </div>{/* dropdown-menu */}
                    </td>
                </tr>
            }
            </>
            )
}
export default ScooterComponent;