import React, { useState } from 'react';
import Header from './Header';
import Navigator from './Navigator';
import PromocodesPage from './PromocodesPage';
import Modal from 'react-modal';

const PromoCodes = () => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <Header />
            <Navigator active={`promocodes`} />
            <PromocodesPage />
            
        </>
    )
}
export default PromoCodes;