import React, { useState } from 'react';
import Header from './Header';
import Navigator from './Navigator';
import AreasPage from './AreasPage';
import useEffectAsync from '../helpers/useEffectAsync';
import Modal from 'react-modal';
import Moment from 'react-moment';
import { makeStyles } from "@material-ui/core/styles";
import { Waypoint } from 'react-waypoint';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert from '@material-ui/lab/Alert';
import { Helmet } from 'react-helmet';


const useStyles = makeStyles(theme => ({
    circular: {
        color: "#00b297"
    }
}));



const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const Rides = (props) => {
    const [usersT, setTP] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [isRidesLoading, setIsRidesLoading] = useState(true);
    const [rides, setRides] = useState([]);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null)
    const [allRides, setAllRides] = useState([]);
    const [disabledEndPayRides, setDisabledEndPayRides] = useState([]);
    const classes = useStyles();
    const gett = async (id) => {
        const requestBody = {
            query: `
            query{
                totalRides
            }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/areas");
                    setTP(resData.data.totalRides);
                    // localStorage.setItem("scooters", JSON.stringify(resData.data.scootersD));
                }).catch(err => {

                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            // setErrorMessage("Error fetching number of rides");
            // setIsSnackbarOpen(true);
            console.log(e);
        }
    }
    const fetchRides = async () => {
        const requestBody = {
            query: `
            query{
                rides(pageNumber: ${pageNumber}){
                    totalPages
                    rides {
                      _id
                      time
                      date
                      status
                      area {
                          name
                      }
                      scooter {
                        code
                      }
                      user {
                        _id
                        name
                        phoneNumber 
                      }
                    }
                }
            }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/areas");
                    const ridesObject = resData.data.rides;
                    setTotalPages(ridesObject.totalPages);
                    setRides((current) => pageNumber === 1 ? [...ridesObject.rides] : [...current, ...ridesObject.rides]);
                    setIsRidesLoading(false);
                    // localStorage.setItem("scooters", JSON.stringify(resData.data.scootersD));
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            setErrorMessage("Error Fetching Rides");
            setIsSnackbarOpen(true);
            console.log(e);
        }
    }
    const endPay = async (id, index) => {
        const requestBody = {
            query: `
            query{
                endRidePay(id: "${id}"){
                    _id
                    time
                    status 
                     user{
                       _id
                       }
                }
            }
            `
        }
        try {
            setDisabledEndPayRides([...disabledEndPayRides, index]);
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {
                    setDisabledEndPayRides([...disabledEndPayRides.filter(item => item !== index)]);
                    return res.json();
                }).then(async (resData) => {
                    const currentRides = rides;
                    currentRides[index].status = resData.data.endRidePay.status;
                    setRides([...currentRides]);
                }).catch(err => {
                    setDisabledEndPayRides([...disabledEndPayRides.filter(item => item !== index)]);
                    setErrorMessage("Error Ending the ride");
                    setIsSnackbarOpen(true);
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            setDisabledEndPayRides([...disabledEndPayRides.filter(item => item !== index)]);
            setErrorMessage("Error Ending the ride");
            setIsSnackbarOpen(true);
            console.log(e);
        }
    }
    const endNoPay = async (id, index) => {
        const requestBody = {
            query: `
            query{
                endRideNotPay(id: "${id}"){
                    _id
                    time
                    status 
                     user{
                       _id
                       }
                }
            }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/Rides");
                    // setScooters(resData.data.rides);
                    console.log(resData.data);
                    const currentRides = rides;
                    currentRides[index].status = resData.data.endRideNotPay.status;
                    setRides([...currentRides]);
                    // localStorage.setItem("scooters", JSON.stringify(resData.data.scootersD));
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            setErrorMessage("Error Ending Ride");
            setIsSnackbarOpen(true);
            console.log(e);
        }
    }
    const deletePromocode = async (id) => {
        const requestBody = {
            query: `
            mutation{
                deleteRide(id: "${id}")
            }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/areas");
                    // setPromocodes(resData.data.promocodes);
                    // localStorage.setItem("promocodes", JSON.stringify(resData.data.scooters));
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            setErrorMessage("Error Deleting Promocode");
            setIsSnackbarOpen(true);
            console.log(e);
        }
    }

    useEffectAsync(
        async () => {
            await fetchRides();
            await gett();
            // computeTotalRevenue();
            // setLoaded(true);
        }, []
    )
    useEffectAsync(
        async () => {
            await fetchRides();
            // computeTotalRevenue();
            // setLoaded(true);
        }, [pageNumber]
    )
    const handleWaypointEnter = () => {
        if (pageNumber < totalPages) {
            setPageNumber(pageNumber + 1);
        }
    }

    const handleRefreshClick = () => {
        setIsRidesLoading(true);
        setPageNumber(1)
        fetchRides();
    }

    const handleAlertClose = () => {
        setIsSnackbarOpen(false);
    }
    return (
        <>
            <Header />
            <Navigator active={`rides`} />
            <Helmet>
                <title>Rides</title>
            </Helmet>
            {isRidesLoading ? <div className="br-mainpanel" style={{ width: "85%", height: "75vh", justifyContent: "center", alignItems: "center", display: "flex", }}><CircularProgress className={classes.circular} /></div> : (
                <div className="br-mainpanel">
                    <div className="br-pageheader pd-y-15 pd-l-20">
                    </div>{/* br-pageheader */}
                    <div className="pd-x-20 pd-sm-x-30 pd-t-20 pd-sm-t-30">
                        <h4 className="tx-gray-800 mg-b-5">Rides ({usersT})</h4>
                        <button onClick={handleRefreshClick} className="btn btn-secondary">Refresh</button>
                        {/* <p className="mg-b-0">Dashboard cards are used in an overview or summary of a project, for crm or form cms.</p> */}
                    </div>

                    <div>

                        <div className="d-flex align-items-center justify-content-start pd-x-20 pd-sm-x-30 pd-t-25 mg-b-20 mg-sm-b-30">
                            <button id="showSubLeft" className="btn btn-secondary mg-r-10 hidden-lg-up"><i className="fa fa-navicon" /></button>
                            {/* START: DISPLAYED FOR MOBILE ONLY */}
                            <div className="dropdown hidden-sm-up">
                                <a href="#" className="btn btn-outline-secondary" data-toggle="dropdown"><i className="icon ion-more" /></a>
                                <div className="dropdown-menu pd-10">
                                    <nav className="nav nav-style-1 flex-column">
                                        <a href className="nav-link">Share</a>
                                        <a href className="nav-link">Download</a>
                                        <div className="dropdown-divider" />
                                        <a href className="nav-link">Info</a>
                                        <a href className="nav-link">Delete</a>
                                    </nav>
                                </div>{/* dropdown-menu */}
                            </div>{/* dropdown */}
                            {/* END: DISPLAYED FOR MOBILE ONLY */}
                            <div className="btn-group hidden-xs-down">
                            </div>{/* btn-group */}
                            {/* <div

                                className="mg-l-auto hidden-xs-down">
                                <a href="/new-member" className="btn btn-info">New Member</a>

                            </div> */}
                        </div>{/* d-flex */}


                        <div className="br-pagebody pd-x-20 pd-sm-x-30">
                            {/* <div className="card bd-0 shadow-base"> */}

                            {rides.map(
                                (ride, index) => {
                                    const baseRidePrice = 6;
                                    const pricePerMinute = 1;
                                    const totalRideMunites = Math.ceil(ride.time / 60000).toFixed(2);
                                    const calculatedFees = baseRidePrice + (totalRideMunites * pricePerMinute);
                                    return (
                                        <div
                                            key={index}
                                            style={{ marginBottom: 20 }}
                                            className="col-sm-12 col-lg-12">
                                            <div className="card shadow-base bd-0">
                                                <div className="card-header bg-transparent d-flex justify-content-between align-items-center">
                                                    <h6 className="card-title tx-uppercase tx-12 mg-b-0">{ride.status} trip
                                                    <a
                                                            href="#"
                                                            onClick={
                                                                () => { deletePromocode(ride._id) }
                                                            }>

                                                            Delete
                                                    </a>
                                                    </h6>
                                                    <span className="tx-12 tx-uppercase"><Moment format="D MMM YYYY" withTitle>
                                                        {ride.date}
                                                    </Moment></span>
                                                </div>{/* card-header */}
                                                <div className="card-body">
                                                    {/* <p className="tx-sm tx-inverse tx-medium mg-b-0">Hardware Monitoring</p>
                                                        <p className="tx-12">Intel Dothraki G125H 2.5GHz</p> */}
                                                    <div className="row align-items-center">
                                                        <div className="col-3 tx-12">Fees</div>{/* col-3 */}
                                                        <div className="col-9">
                                                            <div className="progress rounded-0 mg-b-0">
                                                                <p>{calculatedFees.toFixed(2)} L.E</p>
                                                            </div>{/* progress */}
                                                        </div>{/* col-9 */}
                                                    </div>{/* row */}
                                                    <div className="row align-items-center mg-t-5">
                                                        <div className="col-3 tx-12">Duraion</div>{/* col-3 */}
                                                        <div className="col-9">
                                                            <div className="progress rounded-0 mg-b-0">
                                                                <p>{(ride.time / 60000).toFixed(2)} min</p>
                                                            </div>{/* progress */}
                                                        </div>{/* col-9 */}
                                                    </div>{/* row */}
                                                    <div className="row align-items-center mg-t-5">
                                                        <div className="col-3 tx-12">Scooter Area</div>{/* col-3 */}
                                                        <div className="col-9">
                                                            <div className="progress rounded-0 mg-b-0">
                                                                <p>{ride.area && ride.area.name}</p>
                                                            </div>{/* progress */}
                                                        </div>{/* col-9 */}
                                                    </div>{/* row */}
                                                    <div className="row align-items-center mg-t-5">
                                                        <div className="col-3 tx-12">User</div>{/* col-3 */}
                                                        <div className="col-9">
                                                            <div className="progress rounded-0 mg-b-0">
                                                                <p>{ride.user && ride.user.name}</p>
                                                            </div>{/* progress */}
                                                        </div>{/* col-9 */}
                                                    </div>{/* row */}
                                                    <div className="row align-items-center mg-t-5">
                                                        <div className="col-3 tx-12">Phone</div>{/* col-3 */}
                                                        <div className="col-9">
                                                            <div className="progress rounded-0 mg-b-0">
                                                                <p>{ride.user && ride.user.phoneNumber}</p>
                                                            </div>{/* progress */}
                                                        </div>{/* col-9 */}
                                                    </div>{/* row */}
                                                    <div className="row align-items-center mg-t-5">
                                                        <div className="col-3 tx-12">Time</div>{/* col-3 */}
                                                        <div className="col-9">
                                                            <div className="progress rounded-0 mg-b-0">
                                                                <p>
                                                                    <Moment format="HH:mm" withTitle>
                                                                        {ride.date}
                                                                    </Moment>
                                                                </p>
                                                            </div>{/* progress */}
                                                        </div>{/* col-9 */}
                                                    </div>{/* row */}
                                                    <div className="row align-items-center mg-t-5">
                                                        <div className="col-3 tx-12">Scooter</div>{/* col-3 */}
                                                        <div className="col-9">
                                                            <div className="progress rounded-0 mg-b-0">
                                                                <p>
                                                                    {ride.scooter.code}
                                                                </p>
                                                            </div>{/* progress */}
                                                        </div>{/* col-9 */}
                                                    </div>{/* row */}
                                                    {(ride.status === "ongoing") &&
                                                        <>

                                                            <button
                                                                onClick={() => endNoPay(ride._id, index)}
                                                                class="btn btn-danger btn-block mg-b-10">End no pay </button>
                                                            <button
                                                                onClick={() => endPay(ride._id, index)}
                                                                disabled={disabledEndPayRides.includes(index)}
                                                                class="btn btn-teal active btn-block mg-b-10">End & pay</button>
                                                        </>
                                                    }
                                                </div>{/* card-body */}

                                            </div>{/* card */}
                                        </div>

                                    )
                                }
                            )}
                            <Waypoint
                                onEnter={handleWaypointEnter}
                            >
                                <div style={{ minHeight: "20px", minWidth: "20px" }}></div>
                            </Waypoint>
                            <Snackbar open={isSnackbarOpen} autoHideDuration={5000} onClose={handleAlertClose}>
                                <Alert onClose={handleAlertClose} severity="error">
                                    {errorMessage}
                                </Alert>
                            </Snackbar>


                            {/* </div> */}
                        </div></div>

                </div>
            )}

        </>
    )
}
export default Rides;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};