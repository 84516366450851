import React, { useState } from 'react';
import Header from './Header';
import Navigator from './Navigator';
import useEffectAsync from '../helpers/useEffectAsync';
// import AreasPage from './AreasPage';
import Modal from 'react-modal';
import { Helmet } from 'react-helmet';
const AreasPage = () => {
    // const [isOpen, setIsO/pen] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [areas, setAreas] = useState([]);
    const fetchAreas = async () => {
        const requestBody = {
            query: `
            query{
                areas{
                    _id
                    name
                
                users{
                    _id
                }
                trips{
                    _id
                }
                scooters{
                    _id
                }
            }
            }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/areas");
                    setAreas(resData.data.areas);
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            console.log(e);
        }
    }
    useEffectAsync(
        async () => {
            await fetchAreas();
            setLoaded(true);
        }, []
    )
    return (
        <>
            <Helmet>
                <title>Areas</title>
            </Helmet>
            {loaded &&
                <div className="br-mainpanel">
                    <div className="br-pageheader pd-y-15 pd-l-20">

                    </div>{/* br-pageheader */}
                    <div className="pd-x-20 pd-sm-x-30 pd-t-20 pd-sm-t-30">
                        <h4 className="tx-gray-800 mg-b-5">Areas of Operation</h4>
                        {/* <p className="mg-b-0">Dashboard cards are used in an overview or summary of a project, for crm or form cms.</p> */}
                    </div>
                    <div className="br-pagebody pd-x-20 pd-sm-x-30">

                        <div className="br-pagebody pd-x-20 pd-sm-x-30">
                            <div className="row no-gutters widget-1 shadow-base">
                                <div className="col-sm-6 col-lg-3">
                                    <div className="card">
                                        <div className="card-header">
                                            <h6 className="card-title">All Areas</h6>
                                            <a href><i className="icon ion-android-more-horizontal" /></a>
                                        </div>{/* card-header */}
                                        <div className="card-body">

                                            <span>20</span>
                                        </div>{/* card-body */}

                                    </div>{/* card */}
                                </div>{/* col-3 */}
                                <div className="col-sm-6 col-lg-3 mg-t-1 mg-sm-t-0">
                                    <div className="card">
                                        <div className="card-header">
                                            <h6 className="card-title">Active Areas</h6>
                                            <a href><i className="icon ion-android-more-horizontal" /></a>
                                        </div>{/* card-header */}
                                        <div className="card-body">

                                            <span className="tx-medium tx-inverse tx-32">15</span>
                                        </div>{/* card-body */}

                                    </div>{/* card */}
                                </div>{/* col-3 */}
                                <div className="col-sm-6 col-lg-3 mg-t-1 mg-lg-t-0">
                                    <div className="card">
                                        <div className="card-header">
                                            <h6 className="card-title">Inactive Areas</h6>
                                            <a href><i className="icon ion-android-more-horizontal" /></a>
                                        </div>{/* card-header */}
                                        <div className="card-body">

                                            <span>5</span>
                                        </div>{/* card-body */}

                                    </div>{/* card */}
                                </div>{/* col-3 */}
                                <div className="col-sm-6 col-lg-3 mg-t-1 mg-lg-t-0">
                                    <div className="card">
                                        <div className="card-header">
                                            <h6 className="card-title">Scooters per Area</h6>
                                            <a href><i className="icon ion-android-more-horizontal" /></a>
                                        </div>{/* card-header */}
                                        <div className="card-body">

                                            <span>15</span>
                                        </div>{/* card-body */}

                                    </div>{/* card */}
                                </div>{/* col-3 */}
                            </div>{/* row */}
                        </div>
                    </div>
                    <div>

                        <div className="d-flex align-items-center justify-content-start pd-x-20 pd-sm-x-30 pd-t-25 mg-b-20 mg-sm-b-30">
                            <button id="showSubLeft" className="btn btn-secondary mg-r-10 hidden-lg-up"><i className="fa fa-navicon" /></button>
                            {/* START: DISPLAYED FOR MOBILE ONLY */}
                            <div className="dropdown hidden-sm-up">
                                <a href="#" className="btn btn-outline-secondary" data-toggle="dropdown"><i className="icon ion-more" /></a>
                                <div className="dropdown-menu pd-10">
                                    <nav className="nav nav-style-1 flex-column">
                                        <a href className="nav-link">Share</a>
                                        <a href className="nav-link">Download</a>
                                        <div className="dropdown-divider" />
                                        <a href className="nav-link">Edit</a>
                                        <a href className="nav-link">Delete</a>
                                    </nav>
                                </div>{/* dropdown-menu */}
                            </div>{/* dropdown */}
                            {/* END: DISPLAYED FOR MOBILE ONLY */}
                            <div className="btn-group hidden-xs-down">
                            </div>{/* btn-group */}
                            <div

                                className="mg-l-auto hidden-xs-down">
                                <a href="/new-area" className="btn btn-info">New Area</a>

                            </div>
                        </div>{/* d-flex */}


                        <div className="br-pagebody pd-x-20 pd-sm-x-30">
                            <div className="card bd-0 shadow-base">
                                <table className="table mg-b-0 table-contact">
                                    <thead>
                                        <tr>
                                            <th className="wd-5p">
                                                <label className="ckbox mg-b-0">
                                                    <input type="checkbox" /><span />
                                                </label>
                                            </th>
                                            <th className="tx-10-force tx-mont tx-medium">Name</th>
                                            <th className="tx-10-force tx-mont tx-medium hidden-xs-down">Customers</th>
                                            <th className="tx-10-force tx-mont tx-medium hidden-xs-down">Scooters</th>
                                            <th className="wd-5p hidden-xs-down" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {areas.map(
                                            (area) => {
                                                return (
                                                    <tr>
                                                        <td className="valign-middle">
                                                            <label className="ckbox mg-b-0">
                                                                <input type="checkbox" /><span />
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                {/* <img src="http://via.placeholder.com/280x280" className="wd-40 rounded-circle" alt /> */}
                                                                <div className="mg-l-15">
                                                                    <div className="tx-inverse">{area.name}</div>

                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="valign-middle hidden-xs-down">{area.users && area.users.length}</td>
                                                        <td className="valign-middle hidden-xs-down">{area.scooters && area.scooters.length}</td>
                                                        <td className="dropdown hidden-xs-down">
                                                            <a href="#" data-toggle="dropdown" className="btn pd-y-3 tx-gray-500 hover-info"><i className="icon ion-more" /></a>
                                                            <div className="dropdown-menu dropdown-menu-right pd-10">
                                                                <nav className="nav nav-style-1 flex-column">
                                                                    <a href={`/area/${area._id}`} className="nav-link">Info</a>
                                                                    <a href className="nav-link">Edit</a>
                                                                    <a href className="nav-link">Delete</a>
                                                                </nav>
                                                            </div>{/* dropdown-menu */}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        )}

                                    </tbody>
                                </table>
                            </div>
                        </div></div>

                </div>
            }

        </>
    )
}
export default AreasPage;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};