import React, { useState, useContext } from 'react';
import AuthContext from '../context/auth';

const TFA = () => {
    // const [username, setUser] = useState(null);
    const [TFA, setTFA] = useState(null);
    const context = useContext(AuthContext);

    const login = async () => {

        const login = await localStorage.getItem("loginName");
        // login(login: "${username}", password: "${password}")
        const requestBody = {
            query: `
            query{
                verifyTFA(login: "${login}", TFA: "${TFA}"){
                    userId
                    token
                    tokenExpiration
                }
            }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        // 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    localStorage.setItem("login", JSON.stringify(resData.data.verifyTFA));
                    context.login(resData.data.verifyTFA.token, resData.data.verifyTFA.userId, resData.data.verifyTFA.tokenExpiration);
                    window.location.replace('/');

                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            console.log(e);
        }
    }
    return (
        <>
            <div className="d-flex align-items-center justify-content-center bg-br-primary ht-100v">
                <div className="login-wrapper wd-300 wd-xs-350 pd-25 pd-xs-40 bg-white rounded shadow-base">
                    <div className="signin-logo tx-center tx-28 tx-bold tx-inverse"><span className="tx-normal">[</span> Rabbit <span className="tx-normal">]</span></div>

                    <div className="form-group">
                        Please enter the verification code sent to your phone.
                    
      <input
                            value={TFA}
                            onChange={(e) => { setTFA(e.target.value) }}
                            className="form-control" placeholder="Enter the code" />

                    </div>{/* form-group */}
                    <button
                        onClick={login}
                        type="submit" className="btn btn-info btn-block">Sign In</button>
                </div>{/* login-wrapper */}
            </div>{/* d-flex */}

        </>
    )
}
export default TFA;