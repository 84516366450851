import React from "react"
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polygon } from "react-google-maps"
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
const google = window.google; 
const MapWithADrawingManager = compose(
    withProps({
      googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyC6vfMlg8weB3DKX2-ZF5esMSXbCE1tHYA&v=3.exp&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `400px` }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
  )(props =>
    <GoogleMap
      defaultZoom={12}
      defaultCenter={new google.maps.LatLng(30.0444, 31.2357)}
    >
    <DrawingManager
        onPolygonComplete={props.onPolygonComplete}
        defaultDrawingMode={google.maps.drawing.OverlayType.POLYGON}
        defaultOptions={{
          drawingControl: true,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [
              google.maps.drawing.OverlayType.POLYGON,
            ],
          },
        }}
    />
    </GoogleMap>
  );

class MyFancyComponent extends React.PureComponent {
  state = {
    isMarkerShown: false,
  }

  componentDidMount() {
    this.delayedShowMarker()
  }

  delayedShowMarker = () => {
    setTimeout(() => {
      this.setState({ isMarkerShown: true })
    }, 3000)
  }

  handleMarkerClick = () => {
    this.setState({ isMarkerShown: false })
    this.delayedShowMarker()
  }
  onPolygonComplete = poly => {
    const polyArray = poly.getPath().getArray();
    let paths = [];
    polyArray.forEach(function(path){
      paths.push({lat:path.lat(), long: path.lng()});
    });
    localStorage.setItem("polygon", JSON.stringify(paths));
    console.log(paths);
 }
  render() {
    return (    
      <MapWithADrawingManager
      onPolygonComplete={this.onPolygonComplete}
        isMarkerShown={this.state.isMarkerShown}
        onMarkerClick={this.handleMarkerClick}
      />
    )
  }
}
export default MyFancyComponent;