import Header from './Header';
import Navigator from './Navigator';
import React, { useState, useEffect } from 'react';
import useEffectAsync from "../helpers/useEffectAsync";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';
import AuthContext from '../context/auth';
import MyFancyComponent from './AreaMap';
const NewArea = () => {

    const  [address, setAddress] = useState(null);
    const  [lng, setLng] = useState(null);
    const  [lat, setLat] = useState(null);
    const  [areaID, setAID] = useState(null);
    const  [name, setName] = useState(null);  
    // const authD = useContext(AuthContext);
    const createBorder = async(border) => {
      const requestBody = {
        query: `
        mutation{
          createBorder(
            area: "${localStorage.getItem('areaid')}",
            lng: ${border.long},
            lat: ${border.lat}
          ){
            _id
          }
        }
        `
      }
try {
    await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

        {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                'Content-Type': 'application/json',
                // 'city_id': JSON.parse(localStorage.getItem("city_id"))
            } 
        }
    )
        .then(res => {
            
            return res.json();
        }).then(async (resData) => {  
          
        }).catch(err => {
            throw (err);
        })
} catch (e) {
    // throw  new Error("t");
    console.log(e);
}
    }
    const createArea = async() => {
      const borders = JSON.parse(localStorage.getItem("polygon"));
      await borders.map(
        (border) => {
          createBorder(border);
        }
      );
      const requestBody = {
        query: `
        mutation{
          createArea(
            area: "${localStorage.getItem('areaid')}",
            name: "${name}"
          ){
            _id
          }
        }
        `
      }
      try {
        await fetch(process.env.REACT_APP_BASE_BACKEND_URL,
    
            {
                method: 'POST',
                body: JSON.stringify(requestBody),
                headers: {
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                    'Content-Type': 'application/json',
                    // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                } 
            }
        )
            .then(res => {
                
                return res.json();
            }).then(async (resData) => {
              // window.location.replace("/areas");
            }).catch(err => {
                throw (err);
            })
    } catch (e) {
        // throw  new Error("t");
        console.log(e);
    }
    }
    const preCreateArea = async() => {
      // createBlankArea
      const requestBody = {
        query: `
        mutation{
          createBlankArea{
            _id
          }
        }
        `
      }
try {
    await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

        {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                'Content-Type': 'application/json',
                // 'city_id': JSON.parse(localStorage.getItem("city_id"))
            } 
        }
    )
        .then(res => {
            
            return res.json();
        }).then(async (resData) => {
            setAID(resData.data.createBlankArea._id);
            localStorage.setItem("areaid", resData.data.createBlankArea._id);
            // window.location.replace("/Areas");

        }).catch(err => {
            throw (err);
        })
} catch (e) {
    // throw  new Error("t");
    console.log(e);
}
    }
    const handleSelect = address => {
        geocodeByAddress(address)
          .then(results => getLatLng(results[0]))
          .then(latLng => {
              
            console.log('Success', latLng)
            setLng(latLng.lng);
            setLat(latLng.lat);

          })
          .catch(error => console.error('Error', error));
      };
    useEffectAsync( 
      async() => {
        await preCreateArea();
      }
      ,[]
    )
    return (
        <>
        <Header />
        <Navigator />
        <div className="br-mainpanel">
        <div className="br-section-wrapper">
        <div className="form-layout form-layout-2">
  <div className="row no-gutters">  
  <div className="col-md-12">
      <div className="form-group">
        <label className="form-control-label">Name: <span className="tx-danger">*</span></label>
    
      <input 
      value={name}
      onChange={(e) => setName(e.target.value)}
      type="text" className="form-control" />
      </div>
    </div>
    <div className="col-md-12">
      <div className="form-group">
        <label className="form-control-label">Location: <span className="tx-danger">*</span></label>
      <MyFancyComponent />
   
      </div>
    </div>

  </div>{/* row */}
  <div className="form-layout-footer bd pd-20 bd-t-0">
    <button 
    onClick={createArea}
    className="btn btn-info">Create</button>
    {/* <button className="btn btn-secondary">Cancel</button> */}
  </div>{/* form-group */}
</div>{/* form-layout */}
</div>
        </div>
        </>
    )
}
export default NewArea;