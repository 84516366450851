import React, { useState } from 'react';
import Header from './Header';
import Navigator from './Navigator';
import AreasPage from './AreasPage';
import useEffectAsync from '../helpers/useEffectAsync';
import Modal from 'react-modal';
import UserComponent from './UserComponent'
import { CSVLink, CSVDownload } from "react-csv";
import { Helmet } from 'react-helmet';
const Areas = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [scooters, setScooters] = useState([]);
    const [usersT, setTP] = useState(0);

    // setTP

    const [users, setUsers] = useState([]);
    // /updateBalance
    const updateBalance = async (id, balance) => {
        const requestBody = {
            query: `
            mutation{
                updateBalance(id: "${id}", balance:${balance})
            }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/areas");
                    // setPromocodes(resData.data.promocodes);
                    // localStorage.setItem("promocodes", JSON.stringify(resData.data.scooters));
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            console.log(e);
        }
    }
    const removePC = async (id) => {
        const requestBody = {
            query: `
            mutation{
                removePromocode(id: "${id}")
            }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/areas");
                    // setPromocodes(resData.data.promocodes);
                    // localStorage.setItem("promocodes", JSON.stringify(resData.data.scooters));
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            console.log(e);
        }
    }
    const gett = async (id) => {
        const requestBody = {
            query: `
            query{
                totalUsers
            }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/areas");
                    setTP(resData.data.totalUsers);
                    // localStorage.setItem("scooters", JSON.stringify(resData.data.scootersD));
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            console.log(e);
        }
    }
    const deleteUser = async (id) => {
        const requestBody = {
            query: `
            mutation{
                deleteUser(id: "${id}")
            }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/areas");
                    // setPromocodes(resData.data.promocodes);
                    // localStorage.setItem("promocodes", JSON.stringify(resData.data.scooters));
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            console.log(e);
        }
    }
    const fetchUsers = async () => {
        const requestBody = {
            query: `
            query{
                users{
                    _id
                    name
                    phoneNumber
                    nationalID
                    active
                    TFA
                    balance
                    rentals
                    promocode{
                        code
                    }
                    inRide
                    trips{
                        _id
                    }
                }
            }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/areas");
                    setScooters(resData.data.users);
                    let array = [["name", "phoneNumber", "active", "promocode", "RIDES"]];
                    resData.data.users.map(
                        (pc) => {
                            array.push([pc.name, pc.phoneNumber, pc.active, pc.promocode ? pc.promocode.code : "none", pc.trips.length])
                        }
                    );
                    setUsers(array);
                    // localStorage.setItem("scooters", JSON.stringify(resData.data.scootersD));
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            console.log(e);
        }
    }
    const toggleActivate = async (id) => {
        const requestBody = {
            query: `
            mutation{
                toggleActivate(id: "${id}")
            }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/areas");
                    setScooters(resData.data.users);
                    // localStorage.setItem("scooters", JSON.stringify(resData.data.scootersD));
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            console.log(e);
        }
    }
    const toggleApprove = async (id) => {
        const requestBody = {
            query: `
            mutation{
                toggleApprove(id: "${id}")
            }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/areas");
                    setScooters(resData.data.users);
                    // localStorage.setItem("scooters", JSON.stringify(resData.data.scootersD));
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            console.log(e);
        }
    }
    // const computeTotalRevenue = () => {
    //     let TR = 0.0;
    //     let TP = 0.0;
    //     let lScooters = JSON.parse(localStorage.getItem("scooters"));
    //     lScooters.map(
    //         (scooter) => {
    //             console.log(scooter);
    //             if (scooter.rides) {
    //                 scooter.rides.map(
    //                     (ride) => {
    //                         if (ride.bill){
    //                             if(ride.bill.totalPaid)
    //                                 TR += ride.bill.totalPaid
    //                             if(ride.bill.totalUnpaid){
    //                                 TP += ride.bill.totalUnpaid
    //                             }
    //                         }
    //                     }
    //                 )
    //             }

    //         }
    //     )
    //     setTR(TR);
    // }
    useEffectAsync(
        async () => {
            await fetchUsers();
            await gett();
            setLoaded(true);
            // computeTotalRevenue();
            // setLoaded(true);
        }, []
    )
    return (
        <>
            <Header />
            <Navigator active={`users`} />
            <Helmet>
                <title>Users</title>
            </Helmet>
            {loaded &&
                <>
                    <div className="br-mainpanel">
                        <div className="br-pageheader pd-y-15 pd-l-20">

                        </div>{/* br-pageheader */}
                        <div className="pd-x-20 pd-sm-x-30 pd-t-20 pd-sm-t-30">
                            <h4 className="tx-gray-800 mg-b-5">Users ({usersT} and ) </h4>
                            {/* <p className="mg-b-0">Dashboard cards are used in an overview or summary of a project, for crm or form cms.</p> */}
                        </div>

                        <div>

                            <div className="d-flex align-items-center justify-content-start pd-x-20 pd-sm-x-30 pd-t-25 mg-b-20 mg-sm-b-30">
                                <button id="showSubLeft" className="btn btn-secondary mg-r-10 hidden-lg-up"><i className="fa fa-navicon" /></button>
                                {/* START: DISPLAYED FOR MOBILE ONLY */}
                                <div className="dropdown hidden-sm-up">
                                    <a href="#" className="btn btn-outline-secondary" data-toggle="dropdown"><i className="icon ion-more" /></a>
                                    <div className="dropdown-menu pd-10">
                                        <nav className="nav nav-style-1 flex-column">
                                            <a href className="nav-link">Share</a>
                                            <a href className="nav-link">Download</a>
                                            <div className="dropdown-divider" />
                                            <a href className="nav-link">Info</a>
                                            <a href className="nav-link">Delete</a>
                                        </nav>
                                    </div>{/* dropdown-menu */}
                                </div>{/* dropdown */}
                                {/* END: DISPLAYED FOR MOBILE ONLY */}
                                <div className="btn-group hidden-xs-down">
                                    <div

                                        className="mg-l-auto hidden-xs-down">
                                        <CSVLink data={users} className="btn btn-info">Export as  CSV</CSVLink>

                                    </div>
                                </div>{/* btn-group */}
                                {/* <div

                                className="mg-l-auto hidden-xs-down">
                                <a href="/new-member" className="btn btn-info">New Member</a>

                            </div> */}
                            </div>{/* d-flex */}


                            <div className="br-pagebody pd-x-20 pd-sm-x-30">
                                <div className="card bd-0 shadow-base">
                                    <table className="table mg-b-0 table-contact">
                                        <thead>
                                            <tr>
                                                <th className="wd-5p">
                                                    <label className="ckbox mg-b-0">
                                                        <input type="checkbox" /><span />
                                                    </label>
                                                </th>
                                                <th className="tx-10-force tx-mont tx-medium">Name</th>
                                                <th className="tx-10-force tx-mont tx-medium">Phone</th>
                                                <th className="tx-10-force tx-mont tx-medium">In ride?</th>
                                                <th className="tx-10-force tx-mont tx-medium">Active</th>

                                                <th className="tx-10-force tx-mont tx-medium">Promocode</th>

                                                <th className="tx-10-force tx-mont tx-medium">National ID</th>
                                                <th className="tx-10-force tx-mont tx-medium">Trips</th>
                                                <th className="tx-10-force tx-mont tx-medium">subs</th>

                                                <th className="tx-10-force tx-mont tx-medium">balance</th>



                                                <th className="wd-5p hidden-xs-down" />

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {scooters.map(
                                                (user) => {

                                                    return (
                                                        <UserComponent user={user}
                                                            toggleActivate={toggleActivate}
                                                            toggleApprove={toggleApprove}
                                                            fetchUsers={fetchUsers}
                                                            removePC={removePC}
                                                            updateBalance={updateBalance}
                                                            deleteUser={deleteUser}
                                                        />
                                                    )
                                                }
                                            )}

                                        </tbody>
                                    </table>
                                </div>
                            </div></div>

                    </div>
                </>
            }
        </>
    )
}
export default Areas;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};