import React, { useEffect, useState } from 'react';
import useEffectAsync from '../helpers/useEffectAsync';
import QRCode from 'qrcode.react';
import AMap1 from './AreaMap2';
import Axios from 'axios';
import { Helmet } from "react-helmet";

const Main = () => {
  const [scooters, setScooters] = useState([]);
  const [nUsers, setnUsers] = useState(0);
  const [nRides, setnRides] = useState(0);
  const [urgentScooters, setUrgentScooters] = useState("loading...");

  const fetchnRides = async () => {
    const requestBody = {
      query: `
        query{
          sumOfRides
        }
        `
    }
    try {
      await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

        {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
            'Content-Type': 'application/json',
            // 'city_id': JSON.parse(localStorage.getItem("city_id"))
          }
        }
      )
        .then(res => {

          return res.json();
        }).then(async (resData) => {
          //   window.location.replace("/areas");
          setnRides(resData.data.sumOfRides);
          // localStorage.setItem("scooters", JSON.stringify(resData.data.scootersD));
        }).catch(err => {
          throw (err);
        })
    } catch (e) {
      // throw  new Error("t");
      console.log(e);
    }
  }

  const getUrgentScootersCount = async () => {
    const requestBodyForTotalCount = {
      query: `
    query{
      getUrgentScooters
    }
    `
    }
    const requestBodyForNewGizaCount = {
      query: `
    query{
      getUrgentScootersFromArea(areaId: "5e7df29a0fbe51707169d121")
    }
    `
    }
    const arrayOfRequests = [requestBodyForTotalCount, requestBodyForNewGizaCount]
    try {

      const urgentScooters = await Promise.all(arrayOfRequests.map(requestBody => Axios.post(process.env.REACT_APP_BASE_BACKEND_URL, requestBody, {
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
          'Content-Type': 'application/json',
        }
      })));
      const totalUrgentResponse = Object.values(urgentScooters[0].data.data)[0];
      const totalNewGizaUrgentResponse = Object.values(urgentScooters[1].data.data)[0];
      setUrgentScooters(`Total: ${totalUrgentResponse}       New Giza: ${totalNewGizaUrgentResponse}`)

    } catch (e) {
      // throw  new Error("t");
      console.log(e);
    }
  }
  const fetchNUsers = async () => {
    const requestBody = {
      query: `
        query{
          sumOfUsers
        }
        `
    }
    try {
      await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

        {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
            'Content-Type': 'application/json',
            // 'city_id': JSON.parse(localStorage.getItem("city_id"))
          }
        }
      )
        .then(res => {

          return res.json();
        }).then(async (resData) => {
          //   window.location.replace("/areas");
          setnUsers(resData.data.sumOfUsers);
          // localStorage.setItem("scooters", JSON.stringify(resData.data.scootersD));
        }).catch(err => {
          throw (err);
        })
    } catch (e) {
      // throw  new Error("t");
      console.log(e);
    }
  }
  const fetchScooters = async () => {
    const requestBody = {
      query: `
          query{
              scootersD{
                  _id
                  code
                  lat
                  long
                  area{
                      name
                  }
                  battery
                  phoneBattery
                  status
                  rating  
                  rides{
                      _id
                      bill{
                          totalCost
                          totalPaid
                          totalUnpaid
                      }
                  }

              }
          }
          `
    }
    try {
      await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

        {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
            'Content-Type': 'application/json',
            // 'city_id': JSON.parse(localStorage.getItem("city_id"))
          }
        }
      )
        .then(res => {

          return res.json();
        }).then(async (resData) => {
          //   window.location.replace("/areas");
          setScooters(resData.data.scootersD);
          localStorage.setItem("scooters", JSON.stringify(resData.data.scootersD));
        }).catch(err => {
          throw (err);
        })
    } catch (e) {
      // throw  new Error("t");
      console.log(e);
    }
  }
  useEffectAsync(
    async () => {
      await fetchnRides();
      await fetchNUsers();
      await getUrgentScootersCount();

      setInterval(await fetchScooters(), 1500);
    }, [])
  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <div className="br-mainpanel">
        <div className="pd-30">
          <h4 className="tx-gray-800 mg-b-5">Dashboard</h4>
          {/* <p className="mg-b-0">Do big things with Bracket, the responsive bootstrap 4 admin template.</p> */}
        </div>{/* d-flex */}
        <div className="br-pagebody mg-t-5 pd-x-30">
          <div className="row row-sm">
            <div className="col-sm-6 col-xl-3">
              <div className="bg-teal rounded overflow-hidden">
                <div className="pd-25 d-flex align-items-center">
                  <i className="ion ion-earth tx-60 lh-0 tx-white op-7" />
                  <div className="mg-l-20">
                    <p className="tx-10 tx-spacing-1 tx-mont tx-medium tx-uppercase tx-white-8 mg-b-10">Today's Visits</p>
                    <p className="tx-24 tx-white tx-lato tx-bold mg-b-2 lh-1">829</p>
                    {/* <span className="tx-11 tx-roboto tx-white-6">24% higher</span> */}
                  </div>
                </div>
              </div>
            </div>{/* col-3 */}
            <div className="col-sm-6 col-xl-3 mg-t-20 mg-sm-t-0">
              <div className="bg-danger rounded overflow-hidden">
                <div className="pd-25 d-flex align-items-center">
                  <i className="fa fa-first-aid tx-60 lh-0 tx-white op-7" />
                  <div className="mg-l-20">
                    <p className="tx-10 tx-spacing-1 tx-mont tx-medium tx-uppercase tx-white-8 mg-b-10">Urgent</p>
                    <p className="tx-24 tx-white tx-lato tx-bold mg-b-2 lh-1">{urgentScooters}</p>
                    <span className="tx-11 tx-roboto tx-white-6">Scooters to fix   </span>
                  </div>
                </div>
              </div>
            </div>{/* col-3 */}
            <div className="col-sm-6 col-xl-3 mg-t-20 mg-xl-t-0">
              <div className="bg-primary rounded overflow-hidden">
                <div className="pd-25 d-flex align-items-center">
                  <i className="ion ion-monitor tx-60 lh-0 tx-white op-7" />
                  <div className="mg-l-20">
                    <p className="tx-10 tx-spacing-1 tx-mont tx-medium tx-uppercase tx-white-8 mg-b-10">New Users</p>
                    <p className="tx-24 tx-white tx-lato tx-bold mg-b-2 lh-1">{nUsers}</p>
                    {/* <span className="tx-11 tx-roboto tx-white-6">23% higher</span> */}
                  </div>
                </div>
              </div>
            </div>{/* col-3 */}
            <div className="col-sm-6 col-xl-3 mg-t-20 mg-xl-t-0">
              <div className="bg-br-primary rounded overflow-hidden">
                <div className="pd-25 d-flex align-items-center">
                  <i className="ion ion-clock tx-60 lh-0 tx-white op-7" />
                  <div className="mg-l-20">
                    <p className="tx-10 tx-spacing-1 tx-mont tx-medium tx-uppercase tx-white-8 mg-b-10">trips today</p>
                    <p className="tx-24 tx-white tx-lato tx-bold mg-b-2 lh-1">{nRides}</p>
                    {/* <span className="tx-11 tx-roboto tx-white-6">30% higher</span> */}
                  </div>
                </div>
              </div>
            </div>{/* col-3 */}
          </div>{/* row */}
          <div className="row row-sm mg-t-20">
            <div className="col-8">

              <div className="card bd-0 shadow-base pd-30 mg-t-20">
                <div className="d-flex align-items-center justify-content-between mg-b-30">
                  <div>
                    <h6 className="tx-13 tx-uppercase tx-inverse tx-semibold tx-spacing-1">Scooters Overview</h6>
                    {/* <p className="mg-b-0"><i className="icon ion-calendar mg-r-5" /> From October 2017 - December 2017</p> */}
                  </div>
                  <a href className="btn btn-outline-info btn-oblong tx-11 tx-uppercase tx-mont tx-medium tx-spacing-1 pd-x-30 bd-2">See more</a>
                </div>{/* d-flex */}
                <AMap1 scooters={scooters} />
                <table className="table table-valign-middle mg-b-0">
                  <tbody>
                    {scooters.map(
                      (scooter) => {
                        let revenue = 0;
                        scooter.rides.map(
                          (ride) => {
                            if (ride.bill)
                              revenue += ride.bill.totalCost;
                          }
                        );

                        return (
                          <tr>
                            <td className="pd-l-0-force">
                              {/* <img src="http://via.placeholder.com/280x280" className="wd-40 rounded-circle" alt /> */}
                              <QRCode size={40} value={scooter.code} />

                            </td>
                            <td>
                              <h6 className="tx-inverse tx-14 mg-b-0">{scooter.code}</h6>
                              {/* <span className="tx-12">@deborah.miner</span> */}
                            </td>
                            <td>
                              <h6 className="tx-inverse tx-14 mg-b-0">Phone battery {scooter.phoneBattery}</h6>
                              {/* <span className="tx-12">@deborah.miner</span> */}
                            </td>
                            <td>{scooter.status ? scooter.status : "unknown"}</td>
                            {/* <td><span id="sparkline1">1,4,4,7,5,9,4,7,5,9,1</span></td> */}
                            <td className="pd-r-0-force tx-center"><a href className="tx-gray-600"><i className="icon ion-more tx-18 lh-0" /></a></td>
                          </tr>
                        )
                      }
                    )}
                  </tbody>
                </table>
              </div>{/* card */}

            </div>{/* col-9 */}
            <div className="col-4">

              <div className="card bg-transparent shadow-base bd-0 mg-t-20">
                <div className="bg-primary rounded-top">
                  <div className="pd-x-30 pd-t-30">
                    <h6 className="tx-13 tx-uppercase tx-white tx-semibold tx-spacing-1">Trip Statistics</h6>
                    <p className="mg-b-20 tx-white-6">As of June 12 - 19, 2019</p>
                    <h3 className="tx-lato tx-white mg-b-0">600<i className="icon ion-android-arrow-up tx-white-5" /></h3>
                  </div>
                  <div id="chartLine1" className="wd-100p ht-150" />
                </div>
                <div className="bg-white pd-20 rounded-bottom d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-start">
                    <div><span id="sparkline6">5,4,7,5,9,7,4</span></div>
                    <div className="mg-l-15">
                      <label className="tx-uppercase tx-10 tx-medium tx-spacing-1 mg-b-0">Average Trips</label>
                      <h6 className="tx-inverse mg-b-0 tx-lato tx-bold">200</h6>
                    </div>
                  </div>{/* d-flex */}
                  <div className="d-flex align-items-center">
                    <div><span id="sparkline7">4,7,5,9,4,7,5</span></div>
                    <div className="mg-l-15">
                      <label className="tx-uppercase tx-10 tx-medium tx-spacing-1 mg-b-0">Total Trips</label>
                      <h6 className="tx-inverse mg-b-0 tx-lato tx-bold">3800</h6>
                    </div>
                  </div>{/* d-flex */}
                </div>{/* d-flex */}
              </div>{/* card */}
            </div>{/* col-3 */}
          </div>{/* row */}
        </div>{/* br-pagebody */}
        <footer className="br-footer">
          <div className="footer-left">
            <div className="mg-b-2">Copyright © 2017. Bracket. All Rights Reserved.</div>
            <div>Attentively and carefully made by ThemePixels.</div>
          </div>
          <div className="footer-right d-flex align-items-center">
            <span className="tx-uppercase mg-r-10">Share:</span>
            <a target="_blank" className="pd-x-5" href="https://www.facebook.com/sharer/sharer.php?u=http%3A//themepixels.me/bracket/intro"><i className="fa fa-facebook tx-20" /></a>
            <a target="_blank" className="pd-x-5" href="https://twitter.com/home?status=Bracket,%20your%20best%20choice%20for%20premium%20quality%20admin%20template%20from%20Bootstrap.%20Get%20it%20now%20at%20http%3A//themepixels.me/bracket/intro"><i className="fa fa-twitter tx-20" /></a>
          </div>
        </footer>
      </div>{/* br-mainpanel */}

    </>
  )
}
export default Main;