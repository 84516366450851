import Header from './Header';
import Navigator from './Navigator';
import React, { useState, useEffect } from 'react';
import useEffectAsync from "../helpers/useEffectAsync";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';
import AuthContext from '../context/auth';
import MyFancyComponent from './AreaMap';
const NewScooter = () => {

    const  [address, setAddress] = useState(null);
    const  [lng, setLng] = useState(null);
    const  [lat, setLat] = useState(null);
    const  [areaID, setAID] = useState(null);
    const [areas, setAreas] = useState([]);
    const  [name, setName] = useState(null);  
    // const authD = useContext(AuthContext);
    const registerScooter = async(border) => {
      const requestBody = {
        query: `
        mutation{
          registerScooter
        }
        `
      }
try {
    await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

        {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                'Content-Type': 'application/json',
                // 'city_id': JSON.parse(localStorage.getItem("city_id"))
            } 
        }
    )
        .then(res => {
            
            return res.json();
        }).then(async (resData) => {  
          setName(resData.data.registerScooter);
        }).catch(err => {
            throw (err);
        })
} catch (e) {
    // throw  new Error("t");
    console.log(e);
}
    }
    const fetchAreas = async(border) => {
        const requestBody = {
          query: `
          query{
            areas{
                _id
                name
            }
          }
          `
        }
  try {
      await fetch(process.env.REACT_APP_BASE_BACKEND_URL,
  
          {
              method: 'POST',
              body: JSON.stringify(requestBody),
              headers: {
                  'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                  'Content-Type': 'application/json',
                  // 'city_id': JSON.parse(localStorage.getItem("city_id"))
              } 
          }
      )
          .then(res => {
              
              return res.json();
          }).then(async (resData) => {  
            setAreas(resData.data.areas);
          }).catch(err => {
              throw (err);
          })
  } catch (e) {
      // throw  new Error("t");
      console.log(e);
  }
      }
      
      const createScooter = async(border) => {
        const requestBody = {
          query: `
          mutation{
            createScooter(code:"${name}", area: "${areaID}"){
                _id
            }
          }
          `
        }
  try {
      await fetch(process.env.REACT_APP_BASE_BACKEND_URL,
  
          {
              method: 'POST',
              body: JSON.stringify(requestBody),
              headers: {
                  'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                  'Content-Type': 'application/json',
                  // 'city_id': JSON.parse(localStorage.getItem("city_id"))
              } 
          }
      )
          .then(res => {
              
              return res.json();
          }).then(async (resData) => {  
            // setAreas(resData.data.areas);
            // window.location.replace('/Scooters');
          }).catch(err => {
              throw (err);
          })
  } catch (e) {
      // throw  new Error("t");
      console.log(e);
  }
      }
    useEffectAsync( 
      async() => {
        await registerScooter();
        await fetchAreas();

      }
      ,[]
    )
    return (
        <>
        <Header />
        <Navigator />
        <div className="br-mainpanel">
        <div className="br-section-wrapper">
        <div className="form-layout form-layout-2">
  <div className="row no-gutters">  
  <div className="col-md-6">
      <div className="form-group">
        <label className="form-control-label">Scooter ID: <span className="tx-danger">*</span>
        <a onClick={registerScooter} style={{cursor:"pointer"}}>(R)</a>
        </label>
    
      <input 
      value={name}
      onChange={(e) => setName(e.target.value)}
      type="text" className="form-control" 
      disabled
      />
      </div>
    </div>
    <div className="col-md-6">
      <div className="form-group">
        <label className="form-control-label">Area: <span className="tx-danger">*</span></label>
    
        <select
        onChange={
            (e)=> setAID(e.target.value)
        }
        >
        <option></option>
            {areas.map(
                (area)=> {
                   return <option value={area._id}>{area.name}</option>
                }
            )}
        </select>
      </div>
    </div>

  </div>{/* row */}
  <div className="form-layout-footer bd pd-20 bd-t-0">
    <button 
    onClick={createScooter}
    className="btn btn-info">Create</button>
    {/* <button className="btn btn-secondary">Cancel</button> */}
  </div>{/* form-group */}
</div>{/* form-layout */}
</div>
        </div>
        </>
    )
}
export default NewScooter;