import React, {useState} from 'react';
const UserComponent = ({user,toggleApprove, toggleActivate, deleteUser, removePC, updateBalance, fetchUsers}) => {
    const [visible, setVisible] = useState(true);
    const [balance, setbalance] = useState(user.balance);


    return(
        <>
        {visible&&
        <tr>
        <td className="valign-middle">
            <label className="ckbox mg-b-0">
                <input type="checkbox" /><span />
            </label>
        </td>

        <td>
            <div className="d-flex align-items-center">
                {/* <img src="http://via.placeholder.com/280x280" className="wd-40 rounded-circle" alt /> */}
                <div className="mg-l-15">
                    <div className="tx-inverse">
                        {user.name}
                    </div>
                    {/* <span className="tx-12">Cairo</span>
                <span id="sparkline1">1,4,4,7,5,9,4,7,5,9,1</span> */}
                </div>
            </div>
        </td>
        <td>
            <div className="d-flex align-items-center">
                {/* <img src="http://via.placeholder.com/280x280" className="wd-40 rounded-circle" alt /> */}
                <div className="mg-l-15">
                    <div className="tx-inverse">
                        {user.phoneNumber}
                    </div>
                    {/* <span className="tx-12">Cairo</span>
                <span id="sparkline1">1,4,4,7,5,9,4,7,5,9,1</span> */}
                </div>
            </div>
        </td>
        <td>
            <div className="d-flex align-items-center">
                {/* <img src="http://via.placeholder.com/280x280" className="wd-40 rounded-circle" alt /> */}
                <div className="mg-l-15">
                    <div className="tx-inverse">
                        {user.inRide ? "yes" : "no"}
                    </div>
                    {/* <span className="tx-12">Cairo</span>
                <span id="sparkline1">1,4,4,7,5,9,4,7,5,9,1</span> */}
                </div>
            </div>
        </td>
        <td>
            <div className="d-flex align-items-center">
                {/* <img src="http://via.placeholder.com/280x280" className="wd-40 rounded-circle" alt /> */}
                <div className="mg-l-15">
                    <div className="tx-inverse">
                        {user.active ? "yes" : "no"}
                    </div>
                    {/* <span className="tx-12">Cairo</span>
                <span id="sparkline1">1,4,4,7,5,9,4,7,5,9,1</span> */}
                </div>
            </div>
        </td>
        <td>
            <div className="d-flex align-items-center">
                {/* <img src="http://via.placeholder.com/280x280" className="wd-40 rounded-circle" alt /> */}
                <div className="mg-l-15">
                    <div className="tx-inverse">
                        {user.promocode && user.promocode.code}
                    </div>
                    {/* <span className="tx-12">Cairo</span>
                <span id="sparkline1">1,4,4,7,5,9,4,7,5,9,1</span> */}
                </div>
            </div>
        </td>
        <td>
            <div className="d-flex align-items-center">
                {/* <img src="http://via.placeholder.com/280x280" className="wd-40 rounded-circle" alt /> */}
                <div className="mg-l-15">
                    <div className="tx-inverse">
                        <a href={user.nationalID}>View</a>
                    </div>
                    {/* <span className="tx-12">Cairo</span>
                <span id="sparkline1">1,4,4,7,5,9,4,7,5,9,1</span> */}
                </div>
            </div>
        </td>
        <td>
            <div className="d-flex align-items-center">
                {/* <img src="http://via.placeholder.com/280x280" className="wd-40 rounded-circle" alt /> */}
                <div className="mg-l-15">
                    <div className="tx-inverse">
                        {user.trips.length}
                    </div>
                    {/* <span className="tx-12">Cairo</span>
                <span id="sparkline1">1,4,4,7,5,9,4,7,5,9,1</span> */}
                </div>
            </div>
        </td>
        <td>
            <div className="d-flex align-items-center">
                {/* <img src="http://via.placeholder.com/280x280" className="wd-40 rounded-circle" alt /> */}
                <div className="mg-l-15">
                    <div className="tx-inverse">
                        {user.rentals}
                    </div>
                    {/* <span className="tx-12">Cairo</span>
                <span id="sparkline1">1,4,4,7,5,9,4,7,5,9,1</span> */}
                </div>
            </div>
        </td>
        <td>
            <div className="d-flex align-items-center">
                {/* <img src="http://via.placeholder.com/280x280" className="wd-40 rounded-circle" alt /> */}
                <div className="mg-l-15">
                    <div className="tx-inverse">
                        <input value={balance}

                        onChange={(e) => setbalance(e.target.value)}
                        onKeyDown={(e) => {
                            if(e.key === 'Enter'){
                                // console.log('enter press here! ')
                                updateBalance(user._id, balance);
                              }
                        }}
                        />
                        {/* {user.subs} */}
                    </div>
                    {/* <span className="tx-12">Cairo</span>
                <span id="sparkline1">1,4,4,7,5,9,4,7,5,9,1</span> */}
                </div>
            </div>
        </td>
        <td className="dropdown hidden-xs-down">
            <a href="#" data-toggle="dropdown" className="btn pd-y-3 tx-gray-500 hover-info"><i className="icon ion-more" /></a>
            <div className="dropdown-menu dropdown-menu-right pd-10">
                <nav className="nav nav-style-1 flex-column">
                    {/* <a href className="nav-link">Info</a> */}
                    {/* <a href={`/user/${user.code}`} className="nav-link">Info</a> */}

                    <a
                        onClick={
                            () => {
                                // setLoaded(false);
                                toggleActivate(user._id);
                                fetchUsers();
                                // setLoaded(true);

                            }
                        }
                        style={{
                            cursor: "pointer"
                        }}
                        href className="nav-link">{user.active ? "Deactivate" : "Activate"}</a>
                    <a  
                        href={`/user/${user._id}`}
                             className="nav-link">Info</a>      
                    <a
                        onClick={
                            () => {
                                // setLoaded(false);

                                toggleApprove(user._id);
                                fetchUsers();
                                // setLoaded(true);

                            }
                        }
                        style={{
                            cursor: "pointer"
                        }}
                        href className="nav-link">{user.active ? "Disapprove" : "Approve"}</a>
                          <a
                        onClick={
                            () => {
                                // setLoaded(false);

                                removePC(user._id);
                                fetchUsers();
                                // setLoaded(true);

                            }
                        }
                        style={{
                            cursor: "pointer"
                        }}
                        href className="nav-link">Remove promocode</a>
                    {/* <a href className="nav-link">Delete</a> */}

                    <a
                        onClick={() => {
                            setVisible(false);
                            deleteUser(user._id)
                        }}
                        href className="nav-link">Delete</a>
                   


                </nav>
            </div>{/* dropdown-menu */}
        </td>
    </tr>
        }
        </> 
    )
}
export default UserComponent;