import Header from './Header';
import Navigator from './Navigator';
import React, { useState, useEffect } from 'react';
import useEffectAsync from "../helpers/useEffectAsync";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';
import AuthContext from '../context/auth';
import DatePicker from "react-datepicker";
 
import "react-datepicker/dist/react-datepicker.css";    
import MyFancyComponent from './AreaMap';
const NewPromocode = () => {

    // const  [address, setAddress] = useState(null);
    // const  [lng, setLng] = useState(null);
    // const  [lat, setLat] = useState(null);
    // const  [areaID, setAID] = useState(null);
    // const [areas, setAreas] = useState([]);
    // const  [name, setName] = useState(null);  
    const [code, setCode] = useState(null);
    const [type, setType] = useState(null);
    const [quantity, setQuantity] = useState(null);
    const [expires, setExpires] = useState(null);

    // const authD = useContext(AuthContext);
//     const registerScooter = async(border) => {
//       const requestBody = {
//         query: `
//         mutation{
//           registerScooter
//         }
//         `
//       }
// try {
//     await fetch(`https://rabbit-api.app:9000/graphql/graphql`,

//         {
//             method: 'POST',
//             body: JSON.stringify(requestBody),
//             headers: {
//                 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
//                 'Content-Type': 'application/json',
//                 // 'city_id': JSON.parse(localStorage.getItem("city_id"))
//             } 
//         }
//     )
//         .then(res => {
            
//             return res.json();
//         }).then(async (resData) => {  
//           setName(resData.data.registerScooter);
//         }).catch(err => {
//             throw (err);
//         })
// } catch (e) {
//     // throw  new Error("t");
//     console.log(e);
// }
//     }
//     const fetchAreas = async(border) => {
//         const requestBody = {
//           query: `
//           query{
//             areas{
//                 _id
//                 name
//             }
//           }
//           `
//         }
//   try {
//       await fetch(`https://rabbit-api.app:9000/graphql/graphql`,
  
//           {
//               method: 'POST',
//               body: JSON.stringify(requestBody),
//               headers: {
//                   'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
//                   'Content-Type': 'application/json',
//                   // 'city_id': JSON.parse(localStorage.getItem("city_id"))
//               } 
//           }
//       )
//           .then(res => {
              
//               return res.json();
//           }).then(async (resData) => {  
//             setAreas(resData.data.areas);
//           }).catch(err => {
//               throw (err);
//           })
//   } catch (e) {
//       // throw  new Error("t");
//       console.log(e);
//   }
//       }
      
      const createScooter = async() => {
        const requestBody = {
          query: `
          mutation{
            newPromoCode(code:"${code}", type: "${type}", quantity: ${quantity}, expires: "${expires}"){
                _id
            }
          }
          `
        }
  try {
      await fetch(process.env.REACT_APP_BASE_BACKEND_URL,
  
          {
              method: 'POST',
              body: JSON.stringify(requestBody),
              headers: {
                  'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                  'Content-Type': 'application/json',
                  // 'city_id': JSON.parse(localStorage.getItem("city_id"))
              } 
          }
      )
          .then(res => {
              
              return res.json();
          }).then(async (resData) => {  
            // setAreas(resData.data.areas);
            console.log(resData);
            // window.location.replace('/Promocodes');
          }).catch(err => {
              throw (err);
          })
  } catch (e) {
      // throw  new Error("t");
      console.log(e);
  }
      }
    useEffectAsync( 
      async() => {
    
      }
      ,[]
    )
    return (
        <>
        <Header />
        <Navigator />
        <div className="br-mainpanel">
        <div className="br-section-wrapper">
        <div className="form-layout form-layout-2">
  <div className="row no-gutters">  
  <div className="col-md-6">
      <div className="form-group">
        <label className="form-control-label">Code: <span className="tx-danger">*</span></label>
    
      <input 
      value={code}
      onChange={(e) => setCode(e.target.value)}
      type="text" className="form-control" 
      />
      </div>
    </div>
    <div className="col-md-6">
      <div className="form-group">
        <label className="form-control-label">Type: <span className="tx-danger">*</span></label>
    
        <select
        onChange={
            (e)=> setType(e.target.value)
        }
        >
        <option></option>
        <option value="percentage">Percentage</option>
        <option value="freeTrips">Free Trips</option>
        <option value="specificValue">Specific Value</option>
        
        </select>
      </div>
      
    </div>
    <div className="col-md-6">
      <div className="form-group">
        <label className="form-control-label">Value: <span className="tx-danger">*</span></label>
    
      <input 
      value={quantity}
      onChange={(e) => setQuantity(e.target.value)}
      type="text" className="form-control" 
      />
      </div>
    </div>
    <div className="col-md-6">
      <div className="form-group">
        <label className="form-control-label">Expires: <span className="tx-danger">*</span></label>
    
        <DatePicker
        selected={expires}
        onChange={(date)=> setExpires(date)}
      />
      </div>
    </div>
  </div>{/* row */}
  <div className="form-layout-footer bd pd-20 bd-t-0">
    <button 
    onClick={createScooter}
    className="btn btn-info">Create</button>
    {/* <button className="btn btn-secondary">Cancel</button> */}
  </div>{/* form-group */}
</div>{/* form-layout */}
</div>
        </div>
        </>
    )
}
export default NewPromocode;