import React, { useState } from 'react';
import Header from './Header';
import Navigator from './Navigator';
import useEffectAsync from '../helpers/useEffectAsync';
import ReactStars from 'react-stars'
// import AreasPage from './AreasPage';
import Modal from 'react-modal';
import QRCode from 'qrcode.react';
import { Helmet } from 'react-helmet';
// import ReactStars from 'react-stars';
const OperationsTeam = () => {
    // const [isOpen, setIsO/pen] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [totalRevenue, setTR] = useState(0);
    const [totalUnpaid, setTP] = useState(0);

    const [scooters, setScooters] = useState([]);
    const fetchScooters = async () => {
        const requestBody = {
            query: `
            query{
                operationsTeam{
                    _id
                    name
                    phoneNumber

                }
            }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/areas");
                    setScooters(resData.data.operationsTeam);
                    // localStorage.setItem("scooters", JSON.stringify(resData.data.scootersD));
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            console.log(e);
        }
    }
    // const computeTotalRevenue = () => {
    //     let TR = 0.0;
    //     let TP = 0.0;
    //     let lScooters = JSON.parse(localStorage.getItem("scooters"));
    //     lScooters.map(
    //         (scooter) => {
    //             console.log(scooter);
    //             if (scooter.rides) {
    //                 scooter.rides.map(
    //                     (ride) => {
    //                         if (ride.bill){
    //                             if(ride.bill.totalPaid)
    //                                 TR += ride.bill.totalPaid
    //                             if(ride.bill.totalUnpaid){
    //                                 TP += ride.bill.totalUnpaid
    //                             }
    //                         }
    //                     }
    //                 )
    //             }

    //         }
    //     )
    //     setTR(TR);
    // }
    useEffectAsync(
        async () => {
            await fetchScooters();
            // computeTotalRevenue();
            setLoaded(true);
        }, []
    )
    return (
        <>
            <Header />
            <Navigator active={`operations`} />
            <Helmet>
                <title>Operations Team</title>
            </Helmet>
            {loaded &&
                <div className="br-mainpanel">
                    <div className="br-pageheader pd-y-15 pd-l-20">

                    </div>{/* br-pageheader */}
                    <div className="pd-x-20 pd-sm-x-30 pd-t-20 pd-sm-t-30">
                        <h4 className="tx-gray-800 mg-b-5">Operations Team</h4>
                        {/* <p className="mg-b-0">Dashboard cards are used in an overview or summary of a project, for crm or form cms.</p> */}
                    </div>

                    <div>

                        <div className="d-flex align-items-center justify-content-start pd-x-20 pd-sm-x-30 pd-t-25 mg-b-20 mg-sm-b-30">
                            <button id="showSubLeft" className="btn btn-secondary mg-r-10 hidden-lg-up"><i className="fa fa-navicon" /></button>
                            {/* START: DISPLAYED FOR MOBILE ONLY */}
                            <div className="dropdown hidden-sm-up">
                                <a href="#" className="btn btn-outline-secondary" data-toggle="dropdown"><i className="icon ion-more" /></a>
                                <div className="dropdown-menu pd-10">
                                    <nav className="nav nav-style-1 flex-column">
                                        <a href className="nav-link">Share</a>
                                        <a href className="nav-link">Download</a>
                                        <div className="dropdown-divider" />
                                        <a href className="nav-link">Info</a>
                                        <a href className="nav-link">Delete</a>
                                    </nav>
                                </div>{/* dropdown-menu */}
                            </div>{/* dropdown */}
                            {/* END: DISPLAYED FOR MOBILE ONLY */}
                            <div className="btn-group hidden-xs-down">
                            </div>{/* btn-group */}
                            <div

                                className="mg-l-auto hidden-xs-down">
                                <a href="/new-member" className="btn btn-info">New Member</a>

                            </div>
                        </div>{/* d-flex */}


                        <div className="br-pagebody pd-x-20 pd-sm-x-30">
                            <div className="card bd-0 shadow-base">
                                <table className="table mg-b-0 table-contact">
                                    <thead>
                                        <tr>
                                            <th className="wd-5p">
                                                <label className="ckbox mg-b-0">
                                                    <input type="checkbox" /><span />
                                                </label>
                                            </th>
                                            <th className="tx-10-force tx-mont tx-medium">Name</th>

                                            <th className="wd-5p hidden-xs-down" />

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {scooters.map(
                                            (scooter) => {

                                                return (
                                                    <tr>
                                                        <td className="valign-middle">
                                                            <label className="ckbox mg-b-0">
                                                                <input type="checkbox" /><span />
                                                            </label>
                                                        </td>

                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                {/* <img src="http://via.placeholder.com/280x280" className="wd-40 rounded-circle" alt /> */}
                                                                <div className="mg-l-15">
                                                                    <div className="tx-inverse">
                                                                        {scooter.name}
                                                                    </div>
                                                                    {/* <span className="tx-12">Cairo</span>
                                                                    <span id="sparkline1">1,4,4,7,5,9,4,7,5,9,1</span> */}
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td className="dropdown hidden-xs-down">
                                                            <a href="#" data-toggle="dropdown" className="btn pd-y-3 tx-gray-500 hover-info"><i className="icon ion-more" /></a>
                                                            <div className="dropdown-menu dropdown-menu-right pd-10">
                                                                <nav className="nav nav-style-1 flex-column">
                                                                    {/* <a href className="nav-link">Info</a> */}
                                                                    <a href={`/scooter/${scooter.code}`} className="nav-link">Info</a>
                                                                    <a href className="nav-link">Delete</a>
                                                                </nav>
                                                            </div>{/* dropdown-menu */}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        )}

                                    </tbody>
                                </table>
                            </div>
                        </div></div>

                </div>
            }

        </>
    )
}
export default OperationsTeam;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};