import React from "react"
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polygon } from "react-google-maps"
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
import PMarker from './PMarker';
const google = window.google;

const MapWithADrawingManager = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyC6vfMlg8weB3DKX2-ZF5esMSXbCE1tHYA&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(props =>
  <GoogleMap
    defaultZoom={18}

    defaultCenter={new google.maps.LatLng(props.lat, props.lng)}

  >
    {props.scooters.map(
      (scooter) => {

        return <PMarker scooter={scooter} />
      }
    )}
  </GoogleMap>
);

class MyFancyComponent extends React.PureComponent {
  state = {
    isMarkerShown: false,
    lat: null,
    loaded: false,
    lng: null,
  }
  getGeoLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          console.log(position.coords);
          this.setState({

            // ...prevState.currentLatLng,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            loaded: true,
          }
          )
        }
      )
    } else {
      // er ror => console.log(error)
    }
  }
  async componentDidMount() {
    await this.getGeoLocation();
    this.delayedShowMarker()
  }

  delayedShowMarker = () => {
    setTimeout(() => {
      this.setState({ isMarkerShown: true })
    }, 3000)
  }

  handleMarkerClick = () => {
    this.setState({ isMarkerShown: false })
    this.delayedShowMarker()
  }
  onPolygonComplete = poly => {
    const polyArray = poly.getPath().getArray();
    let paths = [];
    polyArray.forEach(function (path) {
      paths.push({ lat: path.lat(), long: path.lng() });
    });
    localStorage.setItem("polygon", JSON.stringify(paths));
    console.log(paths);
  }
  render() {
    console.log(61, this.props.scooters)
    return (
      <>
        {this.state.loaded &&
          <MapWithADrawingManager
            onPolygonComplete={this.onPolygonComplete}
            isMarkerShown={this.state.isMarkerShown}
            onMarkerClick={this.handleMarkerClick}
            lat={this.state.lat}
            lng={this.state.lng}
            scooters={this.props.scooters}
          />
        }
      </>
    )
  }
}
export default MyFancyComponent;