import React, { useState } from 'react';
import Header from './Header';
import Navigator from './Navigator';
import useEffectAsync from '../helpers/useEffectAsync';
import ReactStars from 'react-stars'
import Moment from 'react-moment';
// import AreasPage from './AreasPage';
import Modal from 'react-modal';
import { CSVLink, CSVDownload } from "react-csv";
import QRCode from 'qrcode.react';
import PCodeComponent from './promocodeComponent';
import { Helmet } from 'react-helmet';
// import ReactStars from 'react-stars';
const PromocodesPage = () => {
    // const [isOpen, setIsO/pen] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [totalRevenue, setTR] = useState(0);
    const [totalUnpaid, setTP] = useState(0);

    const [promocodes, setPromocodes] = useState([]);
    const [promocodesCSV, setPromocodesCSV] = useState([]);

    const deletePromocode = async (id) => {
        // setLoaded(false);
        const requestBody = {
            query: `
            mutation{
                deletePromocode(id: "${id}")
            }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/areas");
                    // setPromocodes(resData.data.promocodes);
                    await fetchPromocodes();
                    // localStorage.setItem("promocodes", JSON.stringify(resData.data.scooters));
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            console.log(e);
        }
        // setLoaded(true);
    }
    const fetchPromocodes = async () => {
        const requestBody = {
            query: `
            query{
                promocodes{
                    _id
                    code
                    users{
                        _id
                    }
                    trips{
                        _id
                    }
                    type
                    quantity
                    expires
                }
            }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/areas");
                    let array = [["code", "type", "QUANTITY", "RIDES"]];
                    resData.data.promocodes.map(
                        (pc) => {
                            array.push([pc.code, pc.type, pc.quantity, pc.trips.length])
                        }
                    );
                    setPromocodesCSV(array);
                    setPromocodes(resData.data.promocodes);
                    localStorage.setItem("promocodes", JSON.stringify(resData.data.scooters));
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            console.log(e);
        }
    }
    const computeTotalRevenue = () => {
        let TR = 0.0;
        let TP = 0.0;
        let lScooters = JSON.parse(localStorage.getItem("scooters"));
        lScooters.map(
            (scooter) => {
                console.log(scooter);
                if (scooter.rides) {
                    scooter.rides.map(
                        (ride) => {
                            if (ride.bill) {
                                TR += ride.bill.totalPaid
                                if (ride.bill.totalUnpaid) {
                                    TP += ride.bill.totalUnpaid
                                }
                            }
                        }
                    )
                }

            }
        )
        setTR(TR);
    }
    useEffectAsync(
        async () => {
            await fetchPromocodes();
            computeTotalRevenue();
            setLoaded(true);
        }, []
    )
    return (
        <>
            <Helmet>
                <title>Promocodes</title>
            </Helmet>
            {loaded &&
                <div className="br-mainpanel">
                    <div className="br-pageheader pd-y-15 pd-l-20">

                    </div>{/* br-pageheader */}
                    <div className="pd-x-20 pd-sm-x-30 pd-t-20 pd-sm-t-30">
                        <h4 className="tx-gray-800 mg-b-5">Promocodes</h4>
                        {/* <p className="mg-b-0">Dashboard cards are used in an overview or summary of a project, for crm or form cms.</p> */}
                    </div>
                    <div className="br-pagebody pd-x-20 pd-sm-x-30">

                        <div className="br-pagebody pd-x-20 pd-sm-x-30">

                        </div>
                    </div>
                    <div>

                        <div className="d-flex align-items-center justify-content-start pd-x-20 pd-sm-x-30 pd-t-25 mg-b-20 mg-sm-b-30">
                            <button id="showSubLeft" className="btn btn-secondary mg-r-10 hidden-lg-up"><i className="fa fa-navicon" /></button>
                            {/* START: DISPLAYED FOR MOBILE ONLY */}
                            <div className="dropdown hidden-sm-up">
                                <a href="#" className="btn btn-outline-secondary" data-toggle="dropdown"><i className="icon ion-more" /></a>
                                <div className="dropdown-menu pd-10">
                                    <nav className="nav nav-style-1 flex-column">
                                        <a href className="nav-link">Share</a>
                                        <a href className="nav-link">Download</a>
                                        <div className="dropdown-divider" />
                                        <a href className="nav-link">Info</a>
                                        <a

                                            href className="nav-link">Delete</a>
                                    </nav>
                                </div>{/* dropdown-menu */}
                            </div>{/* dropdown */}
                            {/* END: DISPLAYED FOR MOBILE ONLY */}
                            <div className="btn-group hidden-xs-down">
                            </div>{/* btn-group */}
                            <div

                                className="mg-l-auto hidden-xs-down">
                                <CSVLink data={promocodesCSV} className="btn btn-info">Export as  CSV</CSVLink>

                            </div>
                            <div

                                className="mg-l-auto hidden-xs-down">
                                <a href="/new-promocode" className="btn btn-info">New Promocode</a>

                            </div>
                        </div>{/* d-flex */}


                        <div className="br-pagebody pd-x-20 pd-sm-x-30">
                            <div className="card bd-0 shadow-base">
                                <table className="table mg-b-0 table-contact">
                                    <thead>
                                        <tr>
                                            <th className="wd-5p">
                                                <label className="ckbox mg-b-0">
                                                    <input type="checkbox" /><span />
                                                </label>
                                            </th>
                                            <th className="tx-10-force tx-mont tx-medium">Code</th>
                                            <th className="tx-10-force tx-mont tx-medium">Type</th>
                                            <th className="tx-10-force tx-mont tx-medium">Quantity</th>


                                            <th className="tx-10-force tx-mont tx-medium hidden-xs-down">Rides</th>
                                            <th className="tx-10-force tx-mont tx-medium hidden-xs-down">Users</th>
                                            <th className="tx-10-force tx-mont tx-medium hidden-xs-down">Expires</th>

                                            {/* <th className="tx-10-force tx-mont tx-medium hidden-xs-down">Total revenue</th> */}

                                            <th className="wd-5p hidden-xs-down" />

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {promocodes.map(
                                            (promocode) => {

                                                return (
                                                    <PCodeComponent promocode={promocode} deletePromocode={deletePromocode} />
                                                )
                                            }
                                        )}

                                    </tbody>
                                </table>
                            </div>
                        </div></div>

                </div>
            }

        </>
    )
}
export default PromocodesPage;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};