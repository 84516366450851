import React, { useState } from 'react';
import Header from './Header';
import Navigator from './Navigator';
import { makeStyles, fade } from "@material-ui/core/styles";
import { Waypoint } from 'react-waypoint';
import CircularProgress from '@material-ui/core/CircularProgress';
import useEffectAsync from '../helpers/useEffectAsync';
import ReactStars from 'react-stars'
// import AreasPage from './AreasPage';
import Modal from 'react-modal';
import QRCode from 'qrcode.react';
import { CSVLink, CSVDownload } from "react-csv";
import { DebounceInput } from 'react-debounce-input';
import { Helmet } from 'react-helmet';


const useStyles = makeStyles(theme => ({
    circular: {
        color: "#00b297"
    },
}));


// import ReactStars from 'react-stars';
const OperationsTeam = () => {
    // const [isOpen, setIsO/pen] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [isSearching, setIsSearching] = useState(false);
    const [totalPages, setTotalPages] = useState(null);
    const [totalRevenue, setTR] = useState(0);
    const [searchedArea, setSearchedArea] = useState("");
    const [totalSubscriptions, setTotalSubscriptions] = useState(0);
    const [subscriptions, setSubscriptions] = useState([]);
    const [scooterT, setScooter] = useState("");
    const [scootersCSV, setScootersCSV] = useState([]);
    const classes = useStyles();
    const searchAddress = async (address, page) => {
        const requestBody = {
            query: `
            query{
                filterSubscriptions(address: "${address}", pageNumber: ${page}) {
                    subscriptions {
                        _id
                        address
                        scooter
                        user {
                          name
                          phoneNumber
                          creditCardNumber
                        }
                        start
                        status
                        price
                        plan
                        end
                        paymentID
                    }
                    totalPages
                }
            }
            `
        }
        return fetch(process.env.REACT_APP_BASE_BACKEND_URL,

            {
                method: 'POST',
                body: JSON.stringify(requestBody),
                headers: {
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                    'Content-Type': 'application/json',
                    // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                }
            })


    }


    const delivered = async (id) => {
        const requestBody = {
            query: `
            mutation{
                delivered(id: "${id}")
            }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/areas");
                    // setScooters(resData.data.subscriptionsD);
                    // localStorage.setItem("scooters", JSON.stringify(resData.data.scootersD));
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            console.log(e);
        }
    }
    const deleteR = async (id) => {
        const requestBody = {
            query: `
            mutation{
                deleteSub(id: "${id}")
            }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/areas");
                    // setScooters(resData.data.subscriptionsD);
                    // localStorage.setItem("scooters", JSON.stringify(resData.data.scootersD));
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            console.log(e);
        }
    }
    const collected = async (id) => {
        const requestBody = {
            query: `
            mutation{
                collected(id: "${id}")
            }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/areas");
                    // setScooters(resData.data.subscriptionsD);
                    // localStorage.setItem("scooters", JSON.stringify(resData.data.scootersD));
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            console.log(e);
        }
    }
    const confirmSub = async (id) => {
        const requestBody = {
            query: `
            mutation{
                confirmSub(id: "${id}")
            }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/areas");
                    // setScooters(resData.data.subscriptionsD);
                    // localStorage.setItem("scooters", JSON.stringify(resData.data.scootersD));
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            console.log(e);
        }
    }
    const addSS = async (id) => {
        const requestBody = {
            query: `
            mutation{
                addSS(id: "${id}", code: "${scooterT}")
            }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/areas");
                    await fetchSubscriptions();
                    // localStorage.setItem("scooters", JSON.stringify(resData.data.scootersD));
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            console.log(e);
        }
    }

    const fetchSubscriptions = async () => {
        const requestBody = {
            query: `
            query{
                subscriptionsD(pageNumber: ${pageNumber}) {
                    subscriptions {
                      _id
                      address
                      scooter
                      user {
                        name
                        phoneNumber
                        creditCardNumber
                      }
                      start
                      status
                      price
                      plan
                      end
                      paymentID
                    }
                    totalPages
                    totalSubs
                  }
            }
            `
        }
        try {
            setIsSearching(false)
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/areas");
                    const subscriptionsArray = resData.data.subscriptionsD.subscriptions;
                    setTotalPages(resData.data.subscriptionsD.totalPages);
                    setSubscriptions((current) => pageNumber === 1 ? [...subscriptionsArray] : [...current, ...subscriptionsArray]);
                    setIsPageLoading(false)
                    setTotalSubscriptions(resData.data.subscriptionsD.totalSubs)
                    let array = [["name", "phoneNumber", "address", "price", "start", "end", "status"]];
                    subscriptionsArray.map(
                        (pc) => {
                            array.push([pc.user.name, pc.user.phoneNumber, pc.address ? pc.address : "none", pc.price, pc.start, pc.end, pc.status ? pc.status : "-"])
                        }
                    );
                    setScootersCSV(array);
                    // localStorage.setItem("scooters", JSON.stringify(resData.data.scootersD));
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            console.log(e);
        }
    }
    const handleWaypointEnter = () => {
        if (pageNumber < totalPages) {
            setPageNumber(pageNumber + 1);
        }
    }
    const handleRefreshClick = () => {
        setIsPageLoading(true);
        setIsSearching(false)
        setPageNumber(1)
        fetchSubscriptions();
    }

    const handleSearchChange = async (e) => {
        try {
            setSearchedArea(e.target.value)
            setPageNumber(1);
            setIsSearching(true);
            const searchedAddressResponse = await searchAddress(e.target.value, 1);
            const response = await searchedAddressResponse.json();
            const responseData = response.data.filterSubscriptions;
            setTotalPages(responseData.totalPages)
            return setSubscriptions(responseData.subscriptions);
        } catch (error) {
            console.log(error)
        }
    }
    useEffectAsync(
        async () => {
            if (isSearching) {
                const searchedAddressResponse = await searchAddress(searchedArea, pageNumber);
                const response = await searchedAddressResponse.json();
                return setSubscriptions((current) => [...current, ...response.data.filterSubscriptions.subscriptions]);
            }
            await fetchSubscriptions();

            // computeTotalRevenue();
            // setLoaded(true);
        }, [pageNumber]
    )
    useEffectAsync(
        async () => {
            await fetchSubscriptions();
        }, []
    )
    return (
        <>
            <Header />
            <Navigator active={`subscriptions`} />
            <Helmet>
                <title>Subscriptions</title>
            </Helmet>   
            {!isPageLoading ?
                <div className="br-mainpanel">
                    <div className="br-pageheader pd-y-15 pd-l-20">

                    </div>{/* br-pageheader */}
                    <div className="pd-x-20 pd-sm-x-30 pd-t-20 pd-sm-t-30">
                        <h4 className="tx-gray-800 mg-b-5">Subscriptions ({totalSubscriptions})</h4>
                        <button onClick={handleRefreshClick} className="btn btn-secondary">Refresh</button>
                        {/* <p className="mg-b-0">Dashboard cards are used in an overview or summary of a project, for crm or form cms.</p> */}
                    </div>
                    <div className="pd-x-10 pd-sm-x-20 pd-t-10 pd-sm-t-20">
                        <DebounceInput
                            element="input"
                            minLength={3}
                            name="Search Area"
                            className="form-control"
                            placeholder="Search Area"
                            debounceTimeout={300}
                            onChange={handleSearchChange}
                        />
                    </div>


                    <div>

                        <div className="d-flex align-items-center justify-content-start pd-x-20 pd-sm-x-30 pd-t-25 mg-b-20 mg-sm-b-30">
                            <button id="showSubLeft" className="btn btn-secondary mg-r-10 hidden-lg-up"><i className="fa fa-navicon" /></button>
                            {/* START: DISPLAYED FOR MOBILE ONLY */}
                            <div className="dropdown hidden-sm-up">
                                <a href="#" className="btn btn-outline-secondary" data-toggle="dropdown"><i className="icon ion-more" /></a>
                                <div className="dropdown-menu pd-10">
                                    <nav className="nav nav-style-1 flex-column">
                                        <a href className="nav-link">Share</a>
                                        <a href className="nav-link">Download</a>
                                        <div className="dropdown-divider" />
                                        <a href className="nav-link">Info</a>
                                        <a href className="nav-link">Delete</a>
                                    </nav>
                                </div>{/* dropdown-menu */}
                            </div>{/* dropdown */}
                            {/* END: DISPLAYED FOR MOBILE ONLY */}
                            <div className="btn-group hidden-xs-down">
                            </div>{/* btn-group */}
                            <div

                                className="mg-l-auto hidden-xs-down">

                                <CSVLink data={scootersCSV} className="btn btn-info">Export as  CSV</CSVLink>

                            </div>
                        </div>{/* d-flex */}


                        <div className="br-pagebody pd-x-20 pd-sm-x-30">
                            <div className="card bd-0 shadow-base">
                                <table className="table mg-b-0 table-contact">
                                    <thead>
                                        <tr >
                                            <th className="tx-10-force tx-mont tx-medium" style={{ textAlign: "center" }}>Name</th>
                                            <th className="tx-10-force tx-mont tx-medium" style={{ textAlign: "center" }}>phone</th>

                                            <th className="tx-10-force tx-mont tx-medium" style={{ textAlign: "center" }}>Area</th>
                                            <th className="tx-10-force tx-mont tx-medium" style={{ textAlign: "center" }}>Days Number</th>

                                            <th className="tx-10-force tx-mont tx-medium" style={{ textAlign: "center" }}>Price</th>
                                            <th className="tx-10-force tx-mont tx-medium" style={{ textAlign: "center" }}>Date</th>

                                            <th className="tx-10-force tx-mont tx-medium" style={{ textAlign: "center" }}>PAYMENT ID (PAYMOB)</th>
                                            <th className="tx-10-force tx-mont tx-medium" style={{ textAlign: "center" }}>Scooter Status</th>
                                            <th className="tx-10-force tx-mont tx-medium" style={{ textAlign: "center" }}>Has Credit Card?</th>
                                            <th className="tx-10-force tx-mont tx-medium" style={{ textAlign: "center" }}>unknown</th>
                                            <th className="tx-10-force tx-mont tx-medium" style={{ textAlign: "center" }}>Options</th>


                                            <th className="wd-5p hidden-xs-down" />

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {subscriptions.map(
                                            (scooter) => {

                                                return (
                                                    <tr>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                {/* <img src="http://via.placeholder.com/280x280" className="wd-40 rounded-circle" alt /> */}
                                                                <div className="mg-l-15">
                                                                    <div className="tx-inverse">
                                                                        {scooter.user.name}
                                                                    </div>
                                                                    {/* <span className="tx-12">Cairo</span>
                                                                    <span id="sparkline1">1,4,4,7,5,9,4,7,5,9,1</span> */}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                {/* <img src="http://via.placeholder.com/280x280" className="wd-40 rounded-circle" alt /> */}
                                                                <div className="mg-l-15">
                                                                    <div className="tx-inverse">
                                                                        {scooter.user.phoneNumber}
                                                                    </div>
                                                                    {/* <span className="tx-12">Cairo</span>
                                                                    <span id="sparkline1">1,4,4,7,5,9,4,7,5,9,1</span> */}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                {/* <img src="http://via.placeholder.com/280x280" className="wd-40 rounded-circle" alt /> */}
                                                                <div className="mg-l-15">
                                                                    <div className="tx-inverse">
                                                                        {scooter.address}
                                                                    </div>
                                                                    {/* <span className="tx-12">Cairo</span>
                                                                    <span id="sparkline1">1,4,4,7,5,9,4,7,5,9,1</span> */}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                {/* <img src="http://via.placeholder.com/280x280" className="wd-40 rounded-circle" alt /> */}
                                                                <div className="mg-l-15">
                                                                    <div className="tx-inverse">
                                                                        {scooter.plan}
                                                                    </div>
                                                                    {/* <span className="tx-12">Cairo</span>
                                                                    <span id="sparkline1">1,4,4,7,5,9,4,7,5,9,1</span> */}
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                {/* <img src="http://via.placeholder.com/280x280" className="wd-40 rounded-circle" alt /> */}
                                                                <div className="mg-l-15">
                                                                    <div className="tx-inverse">
                                                                        {scooter.price}
                                                                    </div>
                                                                    {/* <span className="tx-12">Cairo</span>
                                                                    <span id="sparkline1">1,4,4,7,5,9,4,7,5,9,1</span> */}
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                {/* <img src="http://via.placeholder.com/280x280" className="wd-40 rounded-circle" alt /> */}
                                                                <div className="mg-l-15">
                                                                    <div className="tx-inverse">
                                                                        {scooter.start}
                                                                    </div>
                                                                    {/* <span className="tx-12">Cairo</span>
                                                                    <span id="sparkline1">1,4,4,7,5,9,4,7,5,9,1</span> */}
                                                                </div>
                                                            </div>
                                                        </td>



                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                {/* <img src="http://via.placeholder.com/280x280" className="wd-40 rounded-circle" alt /> */}
                                                                <div className="mg-l-15">
                                                                    <div className="tx-inverse">

                                                                        {scooter.paymentID ?
                                                                            <>
                                                                                {scooter.paymentID.substr(0, 20)}....
                                                                        </>
                                                                            :
                                                                            "Wallet payment"
                                                                        }
                                                                    </div>
                                                                    {/* <span className="tx-12">Cairo</span>
                                                                    <span id="sparkline1">1,4,4,7,5,9,4,7,5,9,1</span> */}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                {/* <img src="http://via.placeholder.com/280x280" className="wd-40 rounded-circle" alt /> */}
                                                                <div className="mg-l-15">
                                                                    <div className="tx-inverse">
                                                                        {scooter.status}
                                                                    </div>
                                                                    {/* <span className="tx-12">Cairo</span>
                                                                    <span id="sparkline1">1,4,4,7,5,9,4,7,5,9,1</span> */}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                {/* <img src="http://via.placeholder.com/280x280" className="wd-40 rounded-circle" alt /> */}
                                                                <div className="mg-l-15">
                                                                    <div className="tx-inverse">
                                                                        {scooter.user.creditCardNumber ? "Yes" : "No"}
                                                                    </div>
                                                                    {/* <span className="tx-12">Cairo</span>
                                                                    <span id="sparkline1">1,4,4,7,5,9,4,7,5,9,1</span> */}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                {/* <img src="http://via.placeholder.com/280x280" className="wd-40 rounded-circle" alt /> */}
                                                                <div className="mg-l-15">
                                                                    <div className="tx-inverse">
                                                                        {scooter.scooter ?
                                                                            scooter.scooter
                                                                            :
                                                                            <input type="text"
                                                                                value={scooterT}
                                                                                onChange={(e) => setScooter(e.target.value)}
                                                                                placeholder="Scooter"
                                                                                onKeyDown={(e) => {
                                                                                    if (e.key === 'Enter') {
                                                                                        // console.log('enter press here! ')
                                                                                        addSS(scooter._id);
                                                                                    }
                                                                                }}
                                                                            />
                                                                        }
                                                                    </div>
                                                                    {/* <span className="tx-12">Cairo</span>
                                                                    <span id="sparkline1">1,4,4,7,5,9,4,7,5,9,1</span> */}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="dropdown hidden-xs-down">
                                                            <a href="#" data-toggle="dropdown" className="btn pd-y-3 tx-gray-500 hover-info"><i className="icon ion-more" /></a>
                                                            <div className="dropdown-menu dropdown-menu-right pd-10">
                                                                <nav className="nav nav-style-1 flex-column">
                                                                    {/* <a href className="nav-link">Info</a> */}
                                                                    <a href={`/scooter/${scooter.code}`} className="nav-link">Info</a>
                                                                    <a
                                                                        onClick={
                                                                            () => {
                                                                                confirmSub(scooter._id)
                                                                            }
                                                                        }
                                                                        className="nav-link">Confirm</a>
                                                                    <a
                                                                        onClick={
                                                                            () => {
                                                                                delivered(scooter._id)
                                                                            }
                                                                        }
                                                                        className="nav-link">Delivered</a>
                                                                    <a
                                                                        onClick={
                                                                            () => {
                                                                                collected(scooter._id)
                                                                            }
                                                                        }
                                                                        className="nav-link">Collected</a>

                                                                    <a
                                                                        onClick={
                                                                            () => {
                                                                                deleteR(scooter._id)
                                                                            }}
                                                                        href className="nav-link">Delete</a>
                                                                </nav>
                                                            </div>{/* dropdown-menu */}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        )}

                                    </tbody>
                                </table>
                            </div>
                            <Waypoint
                                onEnter={handleWaypointEnter}
                            >
                                <div style={{ minHeight: "20px", minWidth: "20px" }}></div>
                            </Waypoint>

                        </div></div>

                </div> : <div className="br-mainpanel" style={{ width: "85%", height: "75vh", justifyContent: "center", alignItems: "center", display: "flex", }}><CircularProgress className={classes.circular} /></div>
            }

        </>
    )
}
export default OperationsTeam;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};