
import React from 'react';
// import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import DnsRoundedIcon from '@material-ui/icons/DnsRounded';
import PermMediaOutlinedIcon from '@material-ui/icons/PhotoSizeSelectActual';
import PublicIcon from '@material-ui/icons/Public';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import TimerIcon from '@material-ui/icons/Timer';
import SettingsIcon from '@material-ui/icons/Settings';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import {useStyles} from '../Theme/theme';
const categories = [
    {
      id: 'Develop',
      children: [
        { id: 'Authentication', icon: <PeopleIcon />, active: true },
        { id: 'Database', icon: <DnsRoundedIcon /> },
        { id: 'Storage', icon: <PermMediaOutlinedIcon /> },
        { id: 'Hosting', icon: <PublicIcon /> },
        { id: 'Functions', icon: <SettingsEthernetIcon /> },
        { id: 'ML Kit', icon: <SettingsInputComponentIcon /> },
      ],
    },
    {
      id: 'Quality',
      children: [
        { id: 'Analytics', icon: <SettingsIcon /> },
        { id: 'Performance', icon: <TimerIcon /> },
        { id: 'Test Lab', icon: <PhonelinkSetupIcon /> },
      ],
    },
  ];
  
  const styles = theme => ({
    categoryHeader: {
      paddingTop: 2,
      paddingBottom: 2,
    },
    categoryHeaderPrimary: {
      color: "#FFFFFF",
    },
    item: {
      paddingTop: 1,
      paddingBottom: 1,
      color: 'rgba(255, 255, 255, 0.7)',
      '&:hover,&:focus': {
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
      },
    },
    itemCategory: {
      backgroundColor: '#232f3e',
      boxShadow: '0 -1px 0 #404854 inset',
      paddingTop: 2,
      paddingBottom: 2,
    },
    firebase: {
      fontSize: 24,
      color: "#FFFFFF",
    },
    itemActiveItem: {
      color: '#4fc3f7',
    },
    itemPrimary: {
      fontSize: 'inherit',
    },
    itemIcon: {
      minWidth: 'auto',
      marginRight: 2,
    },
    divider: {
      marginTop: 2,
    },
  });
  
  function Navigator({active}) {
    // const { classes, ...other } = props;
    const classes = useStyles();
  
    return (
        <div>
        <div className="br-logo"><a href><span>[</span>Rabbit<span>]</span></a></div>
        <div className="br-sideleft overflow-y-auto">
          <label className="sidebar-label pd-x-15 mg-t-20">Navigation</label>
          <div className="br-sideleft-menu">
            <a href="/" className={(active === "mainpage")?"br-menu-link active":"br-menu-link"}>
              <div className="br-menu-item">  
                <i className="menu-item-icon icon ion-ios-home-outline tx-22" />
                <span className="menu-item-label">Dashboard</span>
              </div>{/* menu-item */}
            </a>{/* br-menu-link */}        
            <a href="/areas" className={(active === "areas")?"br-menu-link active":"br-menu-link"}>
              <div className="br-menu-item">  
                <i className="menu-item-icon icon fa fa-users " />
                <span className="menu-item-label">Areas</span>
              </div>{/* menu-item */}
            </a>
            <a href="/Users" className="br-menu-link">
              <div className="br-menu-item">  
                <i className="menu-item-icon icon fa fa-users " />
                <span className="menu-item-label">Users</span>
              </div>{/* menu-item */}
            </a>{/* br-menu-link */}        
            <a href="/Operations"  className={(active === "operations")?"br-menu-link active":"br-menu-link"}>
              <div className="br-menu-item">  
                <i className="menu-item-icon icon fa fa-users " />
                <span className="menu-item-label">Operations Team</span>
              </div>
            </a>
            <a href="/Scooters" className={(active === "scooters")?"br-menu-link active":"br-menu-link"}>
              <div className="br-menu-item">  
                <i className="menu-item-icon icon fa fa-sleigh " />
                <span className="menu-item-label">Scooters</span>
              </div>{/* menu-item */}
            </a>{/* br-menu-link */}     
            <a href="/Subscriptions" className={(active === "subscriptions")?"br-menu-link active":"br-menu-link"}>
              <div className="br-menu-item">  
                <i className="menu-item-icon icon fa fa-sleigh " />
                <span className="menu-item-label">Subscriptions</span>
              </div>
            </a>  
            <a href="/Promocodes" className={(active === "promocodes")?"br-menu-link active":"br-menu-link"}>
              <div className="br-menu-item">  
                <i className="menu-item-icon icon fa fa-sleigh " />
                <span className="menu-item-label">Promocodes</span>
              </div>
            </a>
            <a href="/Rides" className={(active === "rides")?"br-menu-link active":"br-menu-link"}>
              <div className="br-menu-item">  
                <i className="menu-item-icon icon fa fa-sleigh " />
                <span className="menu-item-label">Rides</span>
              </div>
            </a>
    
            <a href="/Cards" className={(active === "cards")?"br-menu-link active":"br-menu-link"}>
              <div className="br-menu-item">  
                <i className="menu-item-icon icon fa fa-sleigh " />
                <span className="menu-item-label">Cards</span>
              </div>
            </a>
          </div>
          {/* <label className="sidebar-label pd-x-15 mg-t-25 mg-b-20 tx-info op-9">Information Summary</label> */}

          <br />
        </div>
      </div>
      
    );
  }
export default withStyles(styles)(Navigator);
  