import React, { useState } from 'react';
import Header from './Header';
import Navigator from './Navigator';
import useEffectAsync from '../helpers/useEffectAsync';
import ReactStars from 'react-stars'
import ScooterComponent from './ScooterComponent';
// import AreasPage from './AreasPage';
import Modal from 'react-modal';
import { CSVLink, CSVDownload } from "react-csv";           
import QRCode from 'qrcode.react'   ;
import { Helmet } from 'react-helmet';
// import ReactStars from 'react-stars';
const ScootersPage = () => {
    // const [isOpen, setIsO/pen] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [totalRevenue, setTR] = useState(0);
    const [totalUnpaid, setTP] = useState(0);

    const [scooters, setScooters] = useState([]);
    const [scootersCSV, setScootersCSV] = useState([]);

    const deleteScooter = async (id) => {
        console.log("a7a")
        const requestBody = {
            query: `
            mutation{
                deleteScooter(id: "${id}")
            }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/areas");
                    // setPromocodes(resData.data.promocodes);
                    // localStorage.setItem("promocodes", JSON.stringify(resData.data.scooters));
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            console.log(e);
        }
    }
    const fetchScooters = async () => {
        const requestBody = {
            query: `
            query{
                scootersD{
                    _id
                    code
                    phoneBattery
                    battery
                    area{
                        name
                    }
                    rating
                    rides{
                        _id
                        bill{
                            totalCost
                            totalPaid
                            totalUnpaid
                        }
                    }

                }
            }
            `
        }
        try {
            await fetch(process.env.REACT_APP_BASE_BACKEND_URL,

                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("login")).token,
                        'Content-Type': 'application/json',
                        // 'city_id': JSON.parse(localStorage.getItem("city_id"))
                    }
                }
            )
                .then(res => {

                    return res.json();
                }).then(async (resData) => {
                    //   window.location.replace("/areas");
                    setScooters(resData.data.scootersD);
                    let array = [["code", "battery", "phone battery", "Area", "Total Rides","Total Revenue"]];
                    resData.data.scootersD.map(
                        (sc) => {
                            let revenue = 0;
                            sc.rides.map(
                                (ride) => {
                                    if (ride.bill)
                                        revenue += ride.bill.totalCost;
                                }
                            )
                            array.push([sc.code, sc.battery, sc.phoneBattery, sc.area.name, sc.rides.length,revenue]);
                        }
                    )
                    setScootersCSV(array);  
                    localStorage.setItem("scooters", JSON.stringify(resData.data.scootersD));
                }).catch(err => {
                    throw (err);
                })
        } catch (e) {
            // throw  new Error("t");
            console.log(e);
        }
    }
    const computeTotalRevenue = () => {
        let TR = 0.0;
        let TP = 0.0;
        let lScooters = JSON.parse(localStorage.getItem("scooters"));
        lScooters.map(
            (scooter) => {
                console.log(scooter);
                if (scooter.rides) {
                    scooter.rides.map(
                        (ride) => {
                            if (ride.bill) {
                                if (ride.bill.totalPaid)
                                    TR += ride.bill.totalPaid
                                if (ride.bill.totalUnpaid) {
                                    TP += ride.bill.totalUnpaid
                                }
                            }
                        }
                    )   
                }

            }
        )
        setTR(TR);
    }
    useEffectAsync(
        async () => {
            await fetchScooters();
            computeTotalRevenue();
            setLoaded(true);
        }, []
    )
    return (
        <>
         <Helmet>
        <title>Scooters</title>
      </Helmet>
            {loaded &&
                <div className="br-mainpanel">
                    <div className="br-pageheader pd-y-15 pd-l-20">

                    </div>{/* br-pageheader */}
                    <div className="pd-x-20 pd-sm-x-30 pd-t-20 pd-sm-t-30">
                        <h4 className="tx-gray-800 mg-b-5">Scooters</h4>
                        {/* <p className="mg-b-0">Dashboard cards are used in an overview or summary of a project, for crm or form cms.</p> */}
                    </div>
                    <div className="br-pagebody pd-x-20 pd-sm-x-30">

                        <div className="br-pagebody pd-x-20 pd-sm-x-30">
                            <div className="row no-gutters widget-1 shadow-base">
                                <div czssName="col-sm-6 col-lg-3">
                                    <div className="card">
                                        <div className="card-header">
                                            <h6 className="card-title">Total Revenue</h6>
                                            <a href><i className="icon ion-android-more-horizontal" /></a>
                                        </div>{/* card-header */}
                                        <div className="card-body">

                                            <span>{totalRevenue.toFixed(2)}</span>
                                        </div>{/* card-body */}

                                    </div>{/* card */}
                                </div>{/* col-3 */}
                                <div className="col-sm-6 col-lg-3 mg-t-1 mg-sm-t-0">
                                    <div className="card">
                                        <div className="card-header">
                                            <h6 className="card-title">Totall Unpaid</h6>
                                            <a href><i className="icon ion-android-more-horizontal" /></a>
                                        </div>{/* card-header */}
                                        <div className="card-body">

                                            <span className="tx-medium tx-inverse tx-32">
                                                {totalUnpaid.toFixed(2)}
                                            </span>
                                        </div>{/* card-body */}

                                    </div>{/* card */}
                                </div>{/* col-3 */}
                                <div className="col-sm-6 col-lg-3 mg-t-1 mg-lg-t-0">
                                    <div className="card">
                                        <div className="card-header">
                                            <h6 className="card-title">Inactive Areas</h6>
                                            <a href><i className="icon ion-android-more-horizontal" /></a>
                                        </div>{/* card-header */}
                                        <div className="card-body">

                                            <span>5</span>
                                        </div>{/* card-body */}

                                    </div>{/* card */}
                                </div>{/* col-3 */}
                                <div className="col-sm-6 col-lg-3 mg-t-1 mg-lg-t-0">
                                    <div className="card">
                                        <div className="card-header">
                                            <h6 className="card-title">Scooters per Area</h6>
                                            <a href><i className="icon ion-android-more-horizontal" /></a>
                                        </div>{/* card-header */}
                                        <div className="card-body">

                                            <span>15</span>
                                        </div>{/* card-body */}

                                    </div>{/* card */}
                                </div>{/* col-3 */}
                            </div>{/* row */}
                        </div>
                    </div>
                    <div>

                        <div className="d-flex align-items-center justify-content-start pd-x-20 pd-sm-x-30 pd-t-25 mg-b-20 mg-sm-b-30">
                            <button id="showSubLeft" className="btn btn-secondary mg-r-10 hidden-lg-up"><i className="fa fa-navicon" /></button>
                            {/* START: DISPLAYED FOR MOBILE ONLY */}
                            <div className="dropdown hidden-sm-up">
                                <a href="#" className="btn btn-outline-secondary" data-toggle="dropdown"><i className="icon ion-more" /></a>
                                <div className="dropdown-menu pd-10">
                                    <nav className="nav nav-style-1 flex-column">
                                        <a href className="nav-link">Share</a>
                                        <a href className="nav-link">Download</a>
                                        <div className="dropdown-divider" />
                                        <a href className="nav-link">Info</a>
                                        <a href className="nav-link">Delete</a>
                                    </nav>
                                </div>{/* dropdown-menu */}
                            </div>{/* dropdown */}
                            {/* END: DISPLAYED FOR MOBILE ONLY */}
                            <div className="btn-group hidden-xs-down">
                            </div>{/* btn-group */}
                            <div

                                className="mg-l-auto hidden-xs-down">
                                <a href="/new-scooter" className="btn btn-info">New Scooter</a>

                            </div>
                                <div

                                className="mg-l-auto hidden-xs-down">
                                <CSVLink data={scootersCSV}>
                               Export as CSV
                                
                                </CSVLink>      

                            </div>
                        </div>{/* d-flex */}


                        <div className="br-pagebody pd-x-20 pd-sm-x-30">
                            <div className="card bd-0 shadow-base">
                                <table className="table mg-b-0 table-contact">
                                    <thead>
                                        <tr>
                                            <th className="wd-5p">
                                                <label className="ckbox mg-b-0">
                                                    <input type="checkbox" /><span />
                                                </label>
                                            </th>
                                            <th className="tx-10-force tx-mont tx-medium">Name</th>
                                            <th className="tx-10-force tx-mont tx-medium">Battery</th>
                                            <th className="tx-10-force tx-mont tx-medium">Phone Battery</th>

                                            <th className="tx-10-force tx-mont tx-medium">QR code</th>
                                            <th className="tx-10-force tx-mont tx-medium">Area</th>


                                            <th className="tx-10-force tx-mont tx-medium hidden-xs-down">Total Rides</th>
                                            <th className="tx-10-force tx-mont tx-medium hidden-xs-down">Rating</th>
                                            <th className="tx-10-force tx-mont tx-medium hidden-xs-down">Total revenue</th>

                                            <th className="wd-5p hidden-xs-down" />

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {scooters.map(
                                            (scooter) => {
                                                let revenue = 0;
                                                scooter.rides.map(
                                                    (ride) => {
                                                        if (ride.bill)
                                                            revenue += ride.bill.totalCost;
                                                    }
                                                )
                                                return (
                                                <ScooterComponent revenue={revenue} scooter={scooter} deleteScooter={deleteScooter} />
                                                )
                                            }
                                        )}

                                    </tbody>
                                </table>
                            </div>
                        </div></div>

                </div>
            }

        </>
    )
}
export default ScootersPage;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};